// Import dependencies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Blurhash } from "react-blurhash";

import ImageModal from "./ImageModal";
import "../styles/swiper-styles.css";

// Example blurhashes
const blurhashes = [
  "LEHV6nWB2yk8pyo0adR*.7kCMdnj",
  "LKO2?U%2Tw=w]~RBVZRi};RPxuwH",
  "LGFFaXYk^6#M@-5c,1J5@[or[Q6.",
  "L7F#*Qai~qofIVM{ofj[%MfRj[M{",
  "L;KNR,%2fkRjK#WBbHae%Lf7ofay",
  "LBBD*%jY00Rj00ay00of9Fog~qay",
  "L8PjI.00j[ay01j[ofj[9ZofRjoz",
  "LQCYlD_N-:of_N~qWBj[~qj[WBt7",
];

// Helper function to get a random blurhash
const getRandomBlurhash = () => {
  const randomIndex = Math.floor(Math.random() * blurhashes.length);
  return blurhashes[randomIndex];
};

// Gallery component with search input
const Gallery = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="gallery-page p-3 sm:p-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg lg:text-3xl font-bold">Gallery</h1>
        <input
          type="text"
          placeholder="Search by Title or Category"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="text-base border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <EventGallery searchTerm={searchTerm} />
    </div>
  );
};

// EventGallery component with loading spinner
const EventGallery = ({ searchTerm }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const getImages = async () => {
      try {
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/gallery"
        );

        const sortedEvents = response.data.sort(
          (a, b) => new Date(b.eventDate) - new Date(a.eventDate)
        );

        setEvents(sortedEvents);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching images:", err);
        setError("Failed to load images.");
        setLoading(false);
      }
    };

    getImages();
  }, []);

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc);
    setIsModalOpen(true);
  };

  if (loading) return <Skeleton />;
  if (error) return <p>{error}</p>;

  const filteredEvents = events.filter(
    (event) =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {filteredEvents.length === 0 ? (
        <p>Oops... No events found. Try another search.</p>
      ) : (
        filteredEvents.map((event) => (
          <div key={event._id} className="event-card mb-8">
            <div className="flex justify-between items-center">
              <div className="space-y-4">
                <h2 className="text-base sm:text-2xl font-semibold sm:font-bold mb-1">
                  {event.title}
                </h2>
                <p className="text-sm text-gray-600 mb-1">
                  Category:{" "}
                  <span className="sm:text-ld text-xs bg-blue-400 px-2 py-1 sm:px-3 sm:py-2 rounded-lg font-Poppins font-bold text-white">
                    {event.category}
                  </span>
                </p>
                <p className="text-sm text-gray-600 mb-1">
                  Date:{" "}
                  <span className="sm:text-ld text-xs bg-amber-900 px-2 py-1 sm:px-3 sm:py-2 rounded-lg font-Poppins font-bold text-white">
                    {new Date(event.eventDate).toLocaleDateString("en-CA")}
                  </span>
                </p>
              </div>
            </div>

            <Swiper
              navigation={{ clickable: true }}
              pagination={{ dynamicBullets: true }}
              modules={[Navigation, Pagination]}
              className="mt-4"
              spaceBetween={10}
              breakpoints={{
                320: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                1024: { slidesPerView: 4 },
                1440: { slidesPerView: 6 },
              }}
            >
              {event.images.map((img, index) => (
                <SwiperSlide key={img._id}>
                  <div
                    className="relative w-full h-72 overflow-hidden rounded-lg"
                    onClick={() => handleImageClick(img.imageUrl)}
                  >
                    <Blurhash
                      hash={getRandomBlurhash()}
                      width="100%"
                      height="100%"
                      resolutionX={32}
                      resolutionY={32}
                      punch={1}
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 loader">
                      <div className="spinner"></div>
                    </div>
                    <img
                      src={img.imageUrl}
                      alt={`Image for ${event.title}`}
                      className="absolute top-0 left-0 w-full h-full object-cover rounded-lg transition-opacity duration-500"
                      onLoad={(e) => {
                        e.target.previousSibling.style.display = "none"; // Hide spinner when loaded
                        e.target.style.opacity = 1;
                      }}
                      style={{ opacity: 0 }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))
      )}
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imgSrc={selectedImage}
      />
    </div>
  );
};

export default Gallery;

const Skeleton = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
    {[...Array(20)].map((_, index) => (
      <div key={index} className="animate-pulse space-y-4 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>
    ))}
  </div>
);

// Securing the Git Hub Streak
// Streak save 2