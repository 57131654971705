import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "./context/AuthContext"; // Correct path to AuthContext

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </AuthProvider>
);

// Helper function to check backend health
async function checkBackendConnection() {
  try {
    const response = await fetch(
      "https://seedafriqueswegp-backend.onrender.com/api/health"
    );
    if (!response.ok) throw new Error("Backend connection failed");
    console.log("Backend is up");
  } catch (error) {
    console.error("Backend connection issue:", error);
    alert("Connection Issues. Retrying...");
    window.location.reload(); // <--- Refresh the page
    // throw error; // Rethrow to handle error in the calling function
  }
}

// Schedule health checks in the background if needed
function scheduleBackendHealthCheck() {
  setInterval(async () => {
    try {
      const response = await fetch(
        "https://seedafriqueswegp-backend.onrender.com/api/health"
      );
      if (!response.ok) throw new Error("Health check failed");
      console.log("Backend is still up");
    } catch (error) {
      console.error("Backend connectivity issue:", error);
      alert("Connection Issues. Some features may be unavailable.");
    }
  }, 300000); // Every 5 minutes
}

// Initialize app
document.addEventListener("DOMContentLoaded", () => {
  const loader = document.getElementById("loader");
  if (loader) loader.style.display = "none";

  // loadData(); // Example: Load initial data on app load
  checkBackendConnection();
  scheduleBackendHealthCheck(); // Optional: Periodic health check
});
