import React, { useState, useEffect } from "react";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../Firebase";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { GrClose } from "react-icons/gr";

// Importing the trucate for the truncated text
import truncate from "html-truncate";

const ManageEvents = () => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: null,
    venue: "",
    category: "",
    eventDate: "",
    blurHash: "",
    imageUrl: "", // Added for Firebase image URL
    registrationLink: "",
    googleMapLink: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const uploadImageToFirebase = async (file) => {
    const storageRef = ref(storage, `event-images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress); // Track upload progress
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl = formData.imageUrl;

      // If a new image file is selected, upload it to Firebase
      if (formData.image) {
        imageUrl = await uploadImageToFirebase(formData.image);
      }

      const eventData = {
        title: formData.title,
        description: formData.description,
        venue: formData.venue,
        category: formData.category,
        eventDate: formData.eventDate,
        blurHash: formData.blurHash,
        imageUrl, // Use Firebase image URL
        registrationLink: formData.registrationLink,
        googleMapLink: formData.googleMapLink,
      };

      const apiCall = isEditing
        ? axios.put(
            `https://seedafriqueswegp-backend.onrender.com/api/events/${editId}`,
            eventData
          )
        : axios.post(
            "https://seedafriqueswegp-backend.onrender.com/api/events",
            eventData
          );

      apiCall
        .then((response) => {
          if (isEditing) {
            // Update event and move it to the top
            setEvents((prevEvents) => [
              response.data,
              ...prevEvents.filter((event) => event._id !== editId),
            ]);
            localStorage.removeItem("editId");
          } else {
            // Add new event to the top
            setEvents((prevEvents) => [response.data, ...prevEvents]);
          }
          resetForm();
          closeModal();
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
    }
  };

  const handleEdit = (event) => {
    console.log("Editing event:", event._id); // Check if we are getting the correct event ID

    setFormData({
      title: event.title,
      description: event.description,
      venue: event.venue,
      category: event.category,
      eventDate: event.eventDate,
      blurHash: event.blurHash,
      imageUrl: event.imageUrl, // Load the image URL for editing
      image: null, // Reset the image file
      registrationLink: event.registrationLink,
      googleMapLink: event.googleMapLink,
    });
    setEditId(event._id);
    setIsEditing(true);

    // Save the event ID to localStorage to persist the editing state
    localStorage.setItem("editId", event._id);
    console.log("Saved editId to localStorage:", event._id); // Check if it's saved

    openModal();
  };

  // First useEffect for fetching events
  useEffect(() => {
    setLoading(true);

    axios
      .get("https://seedafriqueswegp-backend.onrender.com/api/events")
      .then((response) => {
        // Sort events by updatedAt to show the latest first
        const sortedEvents = response.data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        console.log("Filtered and Sorted Events:", sortedEvents);

        // Format the date and extract day, month, and year details
        const formattedEvents = sortedEvents.map((event) => {
          const eventDate = new Date(event.eventDate);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          };
          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(eventDate);

          // Remove all commas and split the formatted date
          const [day, month, dayNumber, year] = formattedDate
            .replace(/,/g, "")
            .split(" ");

          return {
            ...event,
            day, // e.g., "Mon"
            dayNumber, // e.g., "10"
            month, // e.g., "Aug"
            year, // e.g., "2024"
          };
        });

        console.log("Formatted Events:", formattedEvents);

        setEvents(formattedEvents);

        // Handle if there's an event being edited
        const savedEditId = localStorage.getItem("editId");
        if (savedEditId) {
          const eventToEdit = formattedEvents.find(
            (event) => event._id === savedEditId
          );
          if (eventToEdit) handleEdit(eventToEdit);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []); // Fetching logic only runs once on component mount

  const filteredEvents = events.filter(
    (event) =>
      event.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
      event.category.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  // Second useEffect for debouncing the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce delay

    return () => {
      clearTimeout(handler); // Cleanup timeout on every render
    };
  }, [searchTerm]); // This effect depends on searchTerm

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .delete(`https://seedafriqueswegp-backend.onrender.com/api/events/${id}`)
      .then(() => setEvents(events.filter((event) => event._id !== id)))
      .catch((error) => console.error("Error deleting event:", error))
      .finally(() => setLoading(false));
  };

  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      image: null,
      venue: "",
      category: "",
      eventDate: "",
      blurHash: "",
      imageUrl: "", // Reset the image URL
      registrationLink: "",
      googleMapLink: "",
    });
    setIsEditing(false);
    setEditId(null);
  };

  const openModal = () => {
    document.body.classList.add("modal-open"); // Disable background scroll
    setIsModalOpen(true);
  };

  const closeModal = () => {
    document.body.classList.remove("modal-open"); // Re-enable background scroll
    setIsModalOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Create a preview URL
      setFormData({ ...formData, image: e.target.files[0] });
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      // Add 'overflow-hidden' to body to disable scrolling
      document.body.classList.add("overflow-hidden");
    } else {
      // Remove 'overflow-hidden' when modal closes
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup function to ensure the class is removed when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]);

  return (
    <div className="manage-events mt-6">
      <div className="flex justify-between mx-2">
        <div className="flex items-center ">
          <span
            className="bg-buttonColor text-sm px-5 py-2 h-9 rounded-lg hover:bg-green-600 cursor-pointer"
            onClick={openModal}
          >
            {isEditing ? "EDIT EVENT" : "ADD EVENT"}
          </span>
          <div>
            <img
              src="/adminsidebarimages/basil_add-outline.png"
              className="h-11 cursor-pointer"
              alt="Add Icon"
              onClick={openModal}
            />
          </div>
        </div>
        <div>
          {/* Search input field */}
          <input
            type="text"
            placeholder="Search by Title or Category"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="text-base w-24 sm:w-auto border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
      </div>

      {/* Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center">
          <svg
            className="animate-spin h-10 w-10 text-green-600"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : filteredEvents.length === 0 ? (
        <div className="">
          <p className="text-center text-gray-500 p-10 sm:p-0 mt-9 sm:text-xl lg:text-2xl">
            No events found for your search. Try " Stem "
          </p>
        </div>
      ) : (
        <ManageEventsTemp
          events={filteredEvents}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {/* Modal for Adding/Editing Event */}
      {isModalOpen && (
        <div className="z-[53] fixed inset-0 overflow-y-auto scrollbar-hide ">
          <div className=" inset-0 h-auto min-h-[800px] lg:min-h-[1000px] bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto scrollbar-hide ">
            <div className="bg-white p-3 sm:p-8 rounded-lg w-full mx-4 sm:w-1/2 my-10 ">
              <h2 className="text-xl mb-4">
                {isEditing ? "Edit Event" : "Add Event"}
              </h2>

              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Event Title"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />
                <input
                  type="text"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  placeholder="Category eg. STEM"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />
                <input
                  type="text"
                  name="venue"
                  value={formData.venue}
                  onChange={handleInputChange}
                  placeholder="Venue eg. Accra"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />
                <ReactQuill
                  theme="bubble"
                  value={formData.description}
                  onChange={handleEditorChange}
                  placeholder="Event Description"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 border text-left rounded-md"
                />
                <input
                  type="date"
                  name="eventDate"
                  value={formData.eventDate}
                  onChange={handleInputChange}
                  placeholder="Event Date (YYYY-MM-DD)"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />
                <input
                  type="text"
                  name="blurHash"
                  value={formData.blurHash}
                  onChange={handleInputChange}
                  placeholder="BlurHash (Optional)"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />

                <input
                  type="text"
                  name="googleMapLink"
                  value={formData.googleMapLink}
                  onChange={handleInputChange}
                  placeholder="Google map link (Optional)"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />

                <input
                  type="text"
                  name="registrationLink"
                  value={formData.registrationLink}
                  onChange={handleInputChange}
                  placeholder=" Registration Link (Optional)"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />

                {/* The Button to add am Image  */}

                <div>
                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer inline-flex items-center px-4 py-2 border-2 border-green-500 rounded-md bg-lightgreen text-black font-medium"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                    {selectedImage ? "Change Image" : "Upload Image"}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    name="image"
                    onChange={handleImageChange}
                    className="hidden" // Hide the default input
                  />

                  {/* Display a preview if an image is selected */}
                  {selectedImage && (
                    <div className="mt-4">
                      <p className="text-green-600 font-medium">
                        Image selected:
                      </p>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="h-24 w-24 object-cover rounded-md mt-2 border-2 border-green-500"
                      />
                    </div>
                  )}
                </div>

                {uploadProgress > 0 && (
                  <div>
                    <p className=" p-4 bg-cyan-600 rounded-xl text-gray-100 my-2">
                      Uploading:{" "}
                      <span className="font-bold text-orange-200">
                        {Math.round(uploadProgress)}%
                      </span>
                    </p>
                  </div>
                )}

                <div className="flex justify-end mt-3">
                  <button
                    type="button"
                    onClick={() => {
                      closeModal();
                      resetForm();
                      setSelectedImage(false);
                    }}
                    className="mr-4 text-red-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                    disabled={loading}
                  >
                    {isEditing ? "Update Event" : "Submit Event"}
                    {loading && (
                      <svg
                        className="animate-spin h-5 w-5 text-white mx-auto"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageEvents;

// The Template that would be filled with the details from the API.
const ManageEventsTemp = ({ events, onEdit, onDelete }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false); // State to control modal visibility
  const [currentDescription, setCurrentDescription] = useState(""); // State to track the current description

  // Function to open the modal with the full description
  const handleShowMore = (description) => {
    setCurrentDescription(description);
    document.body.classList.add("overflow-hidden"); // Disable background scrolling when the modal is open
    setShowDescriptionModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    document.body.classList.remove("overflow-hidden"); // Re-enable background scrolling
    setShowDescriptionModal(false);
    setCurrentDescription("");
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 mb-4">
      {Array.isArray(events) && events.length > 0 ? (
        events.map((item) => (
          <div
            key={item._id}
            className="rounded-2xl p-2 flex flex-col justify-center md:justify-between h-auto"
          >
            <div className="">
              <div
                className="w-full h-48 bg-cover bg-center mb-4 rounded-t-2xl"
                style={{
                  backgroundImage: `url('${item.imageUrl}')`,
                }}
              >
                <div className="flex justify-end">
                  <div className="rounded-xl border-4 border-green-950 p-2 mr-1 mt-1 bg-paleGreen">
                    <center>
                      <p className="text-xs text-green-950">
                        {item.day || "Unknown Day"}
                      </p>
                    </center>
                    <center>
                      <p className="text-xs text-green-950 font-bold">
                        {item.dayNumber || "--"}
                      </p>
                    </center>
                    <center>
                      <p className="text-xs text-green-950">
                        {item.month + " " + item.year || "Unknown Date"}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <h2 className="rounded-md w-2/4 text-sm bg-green-800 text-lime-50 text-center mt-2 px-1 py-1">
                {item.category}
              </h2>
              <h1 className="text-lg font-bold text-green-950 mt-2">
                {item.title}
              </h1>
            </div>

            {/* Display truncated HTML with "More" button */}
            <div className="text-base my-2 text-green-950">
              {item.description.length > 20 ? (
                <>
                  <div className="flex">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncate(item.description, 20), // Truncate the HTML safely
                      }}
                    />
                    <button
                      onClick={() => handleShowMore(item.description)}
                      className="text-blue-600 ml-2 underline"
                    >
                      More
                    </button>
                  </div>
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description, // Render full description if short
                  }}
                />
              )}
            </div>

            <div className="flex justify-between">
              <a
                className="text-emerald-900 mb-2 underline"
                href={item.googleMapLink || "#"} // Dynamically set the venue link or a fallback "#"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Prevents security risks with external links
              >
                {item.venue}
              </a>
              <a
                className="text-blue-600 mb-2 underline"
                href={item.registrationLink || "#"} // Dynamically set the registration link or fallback "#"
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Prevents security risks with external links
              >
                Register
              </a>
            </div>
            <div className="flex justify-between mt-5">
              <button
                onClick={() => onEdit(item)}
                className=" text-gray-950 bg-gray-400 py-2 px-3 w-28 rounded-lg"
              >
                Edit
              </button>
              <button
                onClick={() => onDelete(item._id)}
                className="text-gray-950 bg-red-500 py-2 px-3 w-28 rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="md:ml-7">No events available</p>
      )}

      {/* Modal for full description */}
      {showDescriptionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[52] flex justify-center items-center">
          <div className="bg-transparent rounded-lg relative w-[450px] max-h-[90vh] overflow-y-auto no-scrollbar">
            <div className="bg-deeperpalegreen p-6 rounded-lg relative mx-1 my-2 ">
              <button
                className="absolute top-2 right-2 text-red-500"
                onClick={handleCloseModal}
              >
                <GrClose size={20} />
              </button>
              <h2 className="text-lg font-bold mb-4">Full Description</h2>
              <div
                dangerouslySetInnerHTML={{ __html: currentDescription }} // Render full HTML safely
                className="text-base text-gray-900"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
