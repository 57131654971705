import GalleryData from "../../data/galleryData";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
import { IoMdAddCircle } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { FaTrashCan } from "react-icons/fa6";
import { MdOutlineDeleteForever } from "react-icons/md";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../Firebase";

// Import dependencies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Blurhash } from "react-blurhash";

// Example blurhashes
const blurhashes = [
  "LEHV6nWB2yk8pyo0adR*.7kCMdnj",
  "LKO2?U%2Tw=w]~RBVZRi};RPxuwH",
  "LGFFaXYk^6#M@-5c,1J5@[or[Q6.",
  "L7F#*Qai~qofIVM{ofj[%MfRj[M{",
  "L;KNR,%2fkRjK#WBbHae%Lf7ofay",
  "LBBD*%jY00Rj00ay00of9Fog~qay",
  "L8PjI.00j[ay01j[ofj[9ZofRjoz",
  "LQCYlD_N-:of_N~qWBj[~qj[WBt7",
];

// Helper function to get a random blurhash
const getRandomBlurhash = () => {
  const randomIndex = Math.floor(Math.random() * blurhashes.length);
  return blurhashes[randomIndex];
};

// The main page
const ManageGallery = () => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNewGalleryModalOpen, setIsNewGalleryModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [addImageGalleryFormData, setAddImageGalleryFormData] = useState({
    title: "",
    category: "",
    image: null,
    imageUrl: "",
    eventDate: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  // When an input is added to the input box it updates
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddImageGalleryFormData({ ...addImageGalleryFormData, [name]: value });
  };

  // Adds the image to the form when selected
  const handleImageChange = (e) => {
    setAddImageGalleryFormData({
      ...addImageGalleryFormData,
      image: e.target.files[0],
    });
  };

  // The Imager upload to firebase storage setup- required
  const uploadImageToFirebase = async (file) => {
    const storageRef = ref(storage, `gallery-images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Firebase upload error:", error);
          alert("Failed to upload image. Please try again.");
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const validateForm = () => {
    const { title, category, eventDate } = addImageGalleryFormData;
    if (!title || !category || !eventDate) {
      alert("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  // When the for is filled the handle submit would control what happens to it when the subkit button is tapped on.
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl = addImageGalleryFormData.imageUrl;

      // Upload the image if provided
      if (addImageGalleryFormData.image) {
        imageUrl = await uploadImageToFirebase(addImageGalleryFormData.image);
      }

      // The event data is kept in this array.
      const eventData = {
        title: addImageGalleryFormData.title,
        category: addImageGalleryFormData.category,
        eventDate: addImageGalleryFormData.eventDate,
        images: [{ imageUrl }],
      };

      console.log("Sending event data:", eventData);

      const response = await axios.post(
        "https://seedafriqueswegp-backend.onrender.com/api/gallery",
        eventData,
        { headers: { "Content-Type": "application/json" } }
      );

      // Reset progress bar after upload completion
      setUploadProgress(0);
      alert("Event added successfully!");
      resetForm();
      closeModal();

      // Add the new event at the top of the list
      setEvents((prevEvents) => [response.data, ...prevEvents]); // Insert at the beginning

      // Refresh the page
      window.location.reload(); // <--- Refresh the page
    } catch (error) {
      console.error(
        "Error adding event:",
        error.response?.data || error.message
      );
      alert("Failed to add event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setAddImageGalleryFormData({
      title: "",
      category: "",
      image: null,
      imageUrl: "",
      eventDate: "",
    });
  };

  const closeModal = () => {
    setIsNewGalleryModalOpen(false);
  };

  return (
    <div className="gallery-page p-3 sm:p-2">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg lg:text-3xl font-bold">Gallery</h1>
        {/* Search input field */}
        <input
          type="text"
          placeholder="Search by Title or Category"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="text-base border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      {/* add button */}
      <div className="flex items-center mb-3">
        <span
          onClick={() => setIsNewGalleryModalOpen(true)}
          className=" bg-buttonColor text-sm px-5 py-2 h-9 rounded-lg hover:bg-green-600 cursor-pointer"
        >
          Add
        </span>
        <div>
          <img
            src="/adminsidebarimages/basil_add-outline.png"
            className="h-11 cursor-pointer"
            alt="Add Icon"
            onClick={() => setIsNewGalleryModalOpen(true)}
          />
        </div>
      </div>
      {/* Pass the searchTerm as a prop to EventGallery */}
      <ManageGalleryTemp searchTerm={searchTerm} />

      {isNewGalleryModalOpen && (
        <div className="z-[49] fixed max-h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
          <div className="bg-white p-3 sm:p-8 rounded-lg w-full mx-4 sm:w-1/2 max-h-screen ">
            <h2 className="text-xl mb-4">Add New Image Gallery</h2>
            <form onSubmit={handleSubmit}>
              {/* The title input box */}
              <input
                type="text"
                name="title"
                value={addImageGalleryFormData.title}
                onChange={handleInputChange}
                placeholder="Title e.g. Stemmnovation"
                style={{
                  border: "2px solid green",
                  color: "black",
                  padding: "5px",
                }}
                className="text-base block w-full mb-4 rounded-md"
              />

              <input
                type="text"
                name="category"
                value={addImageGalleryFormData.category}
                onChange={handleInputChange}
                placeholder="Category e.g. STEM"
                style={{
                  border: "2px solid green",
                  color: "black",
                  padding: "5px",
                }}
                className="text-base block w-full mb-4 rounded-md"
              />

              <input
                type="date"
                name="eventDate"
                value={addImageGalleryFormData.eventDate}
                onChange={handleInputChange}
                placeholder="Date e.g. 2025-10-01"
                style={{
                  border: "2px solid green",
                  color: "black",
                  padding: "5px",
                }}
                className="text-base block w-full mb-4 rounded-md"
              />

              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                className="text-base block w-full mb-4 rounded-md"
                style={{
                  border: "2px solid green",
                  color: "black",
                  padding: "5px",
                }}
              />
              {uploadProgress > 0 && (
                <div className="mt-4 w-full bg-gray-300 rounded-full h-4">
                  <div
                    className="bg-blue-600 h-full rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsNewGalleryModalOpen(false)}
                  className="mr-4 text-red-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                  disabled={loading}
                >
                  Submit Event
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageGallery;

// Individual template
const ManageGalleryTemp = ({ searchTerm }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [isAddimageModalOpen, setIsAddimageModalOpen] = useState(false);
  const [isEditEventDetailsModalOpen, setIsEditEventDetailsModalOpen] =
    useState(false);
  const [isNewGalleryModalOpen, setIsNewGalleryModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addImageGalleryFormData, setAddImageGalleryFormData] = useState({
    title: "",
    category: "",
    image: null,
    imageUrl: "",
    eventDate: "",
  });

  useEffect(() => {
    const getImages = async () => {
      try {
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/gallery"
        );

        // Sort events by creation or update date (newest first)
        const sortedEvents = response.data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setEvents(sortedEvents);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching images:", err);
        setError("Failed to load images.");
        setLoading(false);
      }
    };

    getImages();
  }, []);

  const uploadImageToFirebase = async (file) => {
    const storageRef = ref(storage, `gallery-images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Firebase upload error:", error);
          alert("Failed to upload image. Please try again.");
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const resetForm = () => {
    setAddImageGalleryFormData({
      title: "",
      category: "",
      image: null,
      imageUrl: "",
      eventDate: "",
    });
    setIsEditing(false);
    setEditId(null);
    closeModal();
  };

  const closeModal = () => {
    setIsNewGalleryModalOpen(false);
    setIsEditEventDetailsModalOpen(false);
  };

  const handleAddImageSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const uploadedImages = await Promise.all(
        addImageGalleryFormData.images.map(async (file) => {
          const imageUrl = await uploadImageToFirebase(file); // Upload to Firebase
          return { imageUrl, blurHash: "defaultBlurHash" }; // Return structured data
        })
      );

      const newImageData = { images: uploadedImages };

      // Send POST request to add new images to the selected event
      const response = await axios.post(
        `https://seedafriqueswegp-backend.onrender.com/api/gallery/${selectedEvent._id}/images`,
        newImageData,
        { headers: { "Content-Type": "application/json" } }
      );

      alert("Images added successfully!");
      setIsAddimageModalOpen(false); // Close modal

      // Refresh the page
      window.location.reload(); // <--- Refresh the page
    } catch (error) {
      console.error(
        "Error adding images:",
        error.response?.data || error.message
      );
      alert("Failed to add images. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const [editFormData, setEditFormData] = useState({
    title: "",
    category: "",
    eventDate: "",
  });

  const handleEditDetailsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updatedEventData = {
        title: editFormData.title,
        category: editFormData.category,
        eventDate: editFormData.eventDate,
      };

      const response = await axios.put(
        `https://seedafriqueswegp-backend.onrender.com/api/gallery/${editId}`,
        updatedEventData,
        { headers: { "Content-Type": "application/json" } }
      );

      alert("Event updated successfully!");
      setIsEditEventDetailsModalOpen(false); // Close modal

      // Move the updated event to the top of the list
      setEvents((prevEvents) => [
        { ...response.data, _id: editId },
        ...prevEvents.filter((event) => event._id !== editId),
      ]);

      // Refresh the page
      window.location.reload(); // <--- Refresh the page
    } catch (error) {
      console.error(
        "Error updating event:",
        error.response?.data || error.message
      );
      alert("Failed to update event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (loading) return <Skeleton />;
  if (error) return <p>{error}</p>;

  // Enhanced filtering logic: Search by title or category
  const filteredEvents = events.filter((event) => {
    const title = event.title?.toLowerCase() || "";
    const category = event.category?.toLowerCase() || "";
    const searchTermLower = searchTerm.toLowerCase();

    return (
      title.includes(searchTermLower) || category.includes(searchTermLower)
    );
  });

  // Deteting a whole album
  const handleDeleteEvent = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this gallery?"
    );

    if (!confirmDelete) return;

    setLoading(true);

    try {
      await axios.delete(
        `https://seedafriqueswegp-backend.onrender.com/api/gallery/${id}`
      );

      alert("Gallery deleted successfully!");

      // Remove the event from the state without reloading the page
      setEvents((prevEvents) => prevEvents.filter((event) => event._id !== id));
    } catch (error) {
      console.error(
        "Error deleting gallery:",
        error.response?.data || error.message
      );
      alert("Failed to delete the gallery. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = async (eventId, imageId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmDelete) return;

    setLoading(true);

    try {
      await axios.delete(
        `https://seedafriqueswegp-backend.onrender.com/api/gallery/${eventId}/image/${imageId}`
      );

      alert("Image deleted successfully!");

      // Update the gallery state by removing the deleted image
      setEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((event) =>
          event._id === eventId
            ? {
                ...event,
                images: event.images.filter((img) => img._id !== imageId),
              }
            : event
        );

        // Move the updated gallery to the front
        const updatedEvent = updatedEvents.find(
          (event) => event._id === eventId
        );
        const filteredEvents = updatedEvents.filter(
          (event) => event._id !== eventId
        );

        return [updatedEvent, ...filteredEvents]; // Insert at the front
      });
    } catch (error) {
      console.error(
        "Error deleting image:",
        error.response?.data || error.message
      );
      alert("Failed to delete image. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {filteredEvents.length === 0 ? (
        <p> Oops.... No events found. Try another search.</p>
      ) : (
        filteredEvents.map((event) => (
          <div key={event._id} className="event-card mb-8">
            <div className="flex justify-between items-center">
              <div className="space-y-4">
                <h2 className="text-base sm:text-2xl font-semibold sm:font-bold mb-1">
                  {event.title}
                </h2>
                <p className="text-sm text-gray-600 mb-1">
                  Category:{" "}
                  <span className="sm:text-ld text-xs bg-blue-400 px-2 py-1 sm:px-3 sm:py-2 rounded-lg font-Poppins font-bold text-white">
                    {event.category}
                  </span>
                </p>
                <p className="text-sm text-gray-600 mb-1">
                  Date:{" "}
                  <span className="sm:text-ld text-xs bg-amber-900 px-2 py-1 sm:px-3 sm:py-2 rounded-lg font-Poppins font-bold text-white">
                    {new Date(event.eventDate).toLocaleDateString("en-CA")}{" "}
                    {/* Format to YYYY-MM-DD */}
                  </span>
                </p>
              </div>
              {/* Div with the varous control icons */}
              <div className="flex space-x-2">
                {/* the add image to existing events icon */}
                <div className="bg-slate-400 rounded-md p-1">
                  <IoMdAddCircle
                    className="h-7 w-7 "
                    onClick={() => {
                      setSelectedEvent(event);
                      setIsAddimageModalOpen(true);
                    }}
                  />
                </div>

                {/* The icon to edit stuff like the category , title and the date of the event */}
                <div className="bg-slate-400 rounded-md p-1">
                  <CiEdit
                    className="h-7 w-7 "
                    onClick={() => {
                      setSelectedEvent(event); // Select the event to edit
                      setEditFormData({
                        title: event.title,
                        category: event.category,
                        eventDate: event.eventDate,
                      });
                      setIsEditing(true); // Open the edit modal
                      setEditId(event._id); // Store the ID of the event being edited
                      setIsEditEventDetailsModalOpen(true); // Open the modal
                    }}
                  />
                </div>

                {/* Icon to delete a whole event */}
                <div className="bg-red-300 rounded-md p-1">
                  <MdOutlineDeleteForever
                    className="h-7 w-7 "
                    onClick={() => handleDeleteEvent(event._id)} // Pass the event's ID
                  />
                </div>
              </div>
            </div>

            <Swiper
              navigation={{ clickable: true }}
              pagination={{ clickable: true, dynamicBullets: true }}
              modules={[Navigation, Pagination]}
              className="mt-4"
              spaceBetween={10}
              breakpoints={{
                320: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                1024: { slidesPerView: 4 },
                1440: { slidesPerView: 6 },
              }}
            >
              {event.images && event.images.length > 0 ? (
                event.images.map(
                  (img) =>
                    img && (
                      <SwiperSlide key={img._id}>
                        <div className="relative w-full h-72 md:h-52 overflow-hidden rounded-lg">
                          <Blurhash
                            hash={getRandomBlurhash()}
                            width="100%"
                            height="100%"
                            resolutionX={32}
                            resolutionY={32}
                            punch={1}
                          />
                          <img
                            src={img.imageUrl}
                            alt={`Image for ${event.title}`}
                            className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                            loading="lazy" // Lazy load the image
                            onLoad={(e) => (e.target.style.opacity = 1)}
                            style={{ opacity: 0, transition: "opacity 0.5s" }}
                          />
                          <div className="absolute left-2 top-2 bg-red-600 p-2 rounded-lg">
                            {/* Trash Can Icon Positioned Absolutely */}
                            <FaTrashCan
                              onClick={() =>
                                handleDeleteImage(event._id, img._id)
                              }
                              className=" text-white text-lg cursor-pointer hover:text-gray-300 transition-colors duration-200"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                )
              ) : (
                <SwiperSlide>No images available</SwiperSlide>
              )}
            </Swiper>
          </div>
        ))
      )}

      {/* Modal for adding more images to an album */}
      {isAddimageModalOpen && (
        <div className="z-[49] fixed max-h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
          <div className="bg-white p-3 sm:p-8 rounded-lg w-full mx-4 sm:w-1/2 max-h-screen ">
            <h2 className="text-xl mb-4">
              {" "}
              Add Images to{" "}
              <span className=" font-bold">{selectedEvent.title}</span>
            </h2>
            <form onSubmit={handleAddImageSubmit}>
              <input
                type="file"
                name="images"
                multiple
                onChange={(e) =>
                  setAddImageGalleryFormData({
                    ...addImageGalleryFormData,
                    images: Array.from(e.target.files), // Convert FileList to Array
                  })
                }
                className="text-base block w-full mb-4 rounded-md"
                style={{ border: "2px solid green", padding: "5px" }}
              />

              {uploadProgress > 0 && (
                <div className="mt-4 w-full bg-gray-300 rounded-full h-4">
                  <div
                    className="bg-blue-600 h-full rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsAddimageModalOpen(false)}
                  className="mr-4 text-red-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                  disabled={loading}
                >
                  Submit Images
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isEditEventDetailsModalOpen && selectedEvent && (
        <div className="z-[49] fixed max-h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
          <div className="bg-white p-3 sm:p-8 rounded-lg w-full mx-4 sm:w-1/2 max-h-screen">
            <h2 className="text-xl mb-4">
              Edit details for{" "}
              <span className="font-bold">{selectedEvent.title}</span>
            </h2>
            <form onSubmit={handleEditDetailsSubmit}>
              <input
                type="text"
                name="title"
                value={editFormData.title}
                onChange={handleEditInputChange}
                className="text-base block w-full mb-4 rounded-md"
                placeholder="Title"
                style={{ border: "2px solid green", padding: "5px" }}
              />

              <input
                type="text"
                name="category"
                value={editFormData.category}
                onChange={handleEditInputChange}
                className="text-base block w-full mb-4 rounded-md"
                placeholder="Category"
                style={{ border: "2px solid green", padding: "5px" }}
              />

              <input
                type="date"
                name="eventDate"
                value={editFormData.eventDate}
                onChange={handleEditInputChange}
                className="text-base block w-full mb-4 rounded-md"
                style={{ border: "2px solid green", padding: "5px" }}
              />

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsEditEventDetailsModalOpen(false)}
                  className="mr-4 text-red-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                  disabled={loading}
                >
                  Submit Changes
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 text-white mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// Skeleton loader
// Skeleton card component
const SkeletonCard = () => (
  <div className="animate-pulse space-y-4 p-2 rounded-lg">
    <div className="flex justify-between items-center">
      <div className="space-y-2">
        <div className="w-full h-3 bg-gray-300 rounded"></div>
        <div className="w-28 h-3 bg-gray-300 rounded"></div>
      </div>
      <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
    </div>
    <div className="w-full h-72 bg-gray-300 rounded"></div>
  </div>
);

// Skeleton loader component
const Skeleton = () => {
  // Array to represent the number of skeleton cards needed
  const skeletonArray = Array.from({ length: 8 });

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {skeletonArray.map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </div>
  );
};


// Securing the Github streak