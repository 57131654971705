import React, { useState, useEffect, useRef } from "react";
import { MdCloseFullscreen } from "react-icons/md";


const ImageModal = ({ isOpen, onClose, imgSrc }) => {


  // useEffect to handle scrolling behavior
  useEffect(() => {
    if (isOpen) {
      // Disable scrolling when modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when modal is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup function to ensure scrolling is enabled when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div className="relative bg-white rounded-lg ">
        {/* Close button with MdCloseFullscreen icon */}
        <div className="flex justify-center items-center bg-white h-9 w-9 absolute top-2 right-2 rounded-full">
          <MdCloseFullscreen
            onClick={onClose}
            className="text-black"
            style={{ cursor: "pointer", fontSize: "24px" }} // Adjust font size to ensure it's centered properly
          />
        </div>
        <img
          src={imgSrc}
          alt="Clicked Image"
          className="max-h-screen max-w-screen"
        />
      </div>
    </div>
  );
};

export default ImageModal;
