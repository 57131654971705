import React from "react";

const Blog = () => {
  return (
    <div className=" mx-2 sm:mx-5 my-10 text-center ">
      <p> Our blog section is currently under development and will be available soon. Thank you for your patience! </p>
    </div>
  );
};

export default Blog;
