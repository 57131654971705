"use client";
import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { Blurhash } from "react-blurhash";

import { v4 as uuidv4 } from "uuid"; // To generate unique codes

// Main
export default function JoinUs() {
  return (
    <>
      <JoinUsMobileVer />
    </>
  );
}

// COMPONENTS

// Join us Component for mobile devices
const JoinUsMobileVer = () => {
  // State variables to hold form data
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [enteredCode, setEnteredCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contact, setContact] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [whyJoinSeedafrique, setWhyJoinSeedafrique] = useState("");
  const [previousExperience, setPreviousExperience] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [skillsTalents, setSkillsTalents] = useState("");
  const [communicationMethod, setCommunicationMethod] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [educationLevel, setEducationLevel] = useState(""); // For the dropdown
  const [institution, setInstitution] = useState(""); // For the institution input
  const [howDidYouHearOfUs, setHowDidYouHearOfUs] = useState("");

  const [stemInterest, setStemInterest] = useState([]); // Update this to an array to hold multiple interests

  // List of available interests
  const interestOptions = [
    "Select an option",
    "Website Designing",
    "Electronics",
    "Robotics",
    "Sports",
    "Drones",
    "Graphic Designing",
    "Coding",
    "Entrepreneurship",
    "Digital Skills",
  ];

  // Handler for selecting interests
  const handleInterestChange = (event) => {
    const selectedValue = event.target.value;

    // If the option is already selected, remove it from the array
    if (stemInterest.includes(selectedValue)) {
      setStemInterest(
        stemInterest.filter((interest) => interest !== selectedValue)
      );
    } else if (stemInterest.length < 3) {
      // Only add the option if less than 3 options are selected
      setStemInterest([...stemInterest, selectedValue]);
    } else {
      // Alert if more than 3 options are selected
      alert("You can select up to 3 interests.");
    }
  };

  const removeInterest = (interestToRemove) => {
    setStemInterest(
      stemInterest.filter((interest) => interest !== interestToRemove)
    );
  };

  // State to track if the verification button is disabled
  const [isVerificationButtonDisabled, setIsVerificationButtonDisabled] =
    useState(false);

  const [loading, setLoading] = useState(false); // State to manage loading

  // New state to track if the verification code was incorrect
  const [codeMismatch, setCodeMismatch] = useState(false);

  // State to track if the email format is valid
  const [emailError, setEmailError] = useState("");

  // Function to validate email format using regex
  const validateEmailFormat = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Step 1: Send verification code to user's email
  // Function to send verification email
  const sendVerificationEmail = async () => {
    setLoading(true);

    validateEmailFormat();
    try {
      const response = await fetch(
        "https://seedafriqueswegp-backend.onrender.com/api/auth/send-verification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, firstName, surName }), // Sending necessary data
        }
      );

      if (response.ok) {
        const { verificationCode } = await response.json();
        setVerificationCode(verificationCode); // Set the code for verification
        setCodeSent(true);
        setLoading(false);
      } else {
        alert("Failed to send verification email.");
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  // Step 2: Handle verification code input and check if it matches
  const verifyCode = () => {
    setLoading(true);
    if (enteredCode === verificationCode) {
      setEmailVerified(true);
      setCodeMismatch(false); // Reset mismatch state
      setLoading(false);
      alert("Email verified successfully!");
    } else {
      setLoading(false);
      setErrorMessage("Verification code does not match.");
      setCodeMismatch(true); // Set code mismatch state to true
    }
  };

  // handle Submit handles the submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Ensure the email has been verified before submitting the form
    if (!emailVerified) {
      setErrorMessage("Please verify your email before submitting the form.");
      return;
    }

    // Show loading spinner for email check and form submission
    setLoading(true);

    // First, check if the email already exists in the database
    try {
      const emailCheckResponse = await fetch(
        "https://seedafriqueswegp-backend.onrender.com/api/members/check-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }), // Send the email to check
        }
      );

      const emailCheckResult = await emailCheckResponse.json();

      if (emailCheckResult.exists) {
        // If email already exists, show an error message and stop submission
        alert(
          "This email has already been used. Please use a different email."
        );
        setEmailVerified(false); // Brings the it verficatiohn pact to a state where it has not been verified
        setLoading(false); // Stop loading spinner if email exists
        setCodeSent(false); // Brinds the verfication to that state that a codehas not yet been sent
        return; // Stop form submission if email exists
      }
    } catch (err) {
      console.error("Error checking email:", err);
      alert("An error occurred while checking the email. Please try again.");
      return;
    }

    // Prepare the data to send to the backend
    const memberData = {
      firstName,
      surName,
      email,
      contact,
      dob,
      gender,
      location,
      address,
      whyJoinSeedafrique,
      previousExperience,
      emergencyContact,
      skillsTalents,
      communicationMethod,
      howDidYouHearOfUs,
      educationLevel,
      institution,
      stemInterest,
      acceptedTerms,
    };

    // setLoading(true); // Show loading spinner
    try {
      setLoading(true); // Show loading spinner

      // Send a POST request to your backend API
      const response = await fetch(
        "https://seedafriqueswegp-backend.onrender.com/api/members/add-request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(memberData), // Convert member data to JSON
        }
      );

      // Log the response status
      console.log("Response Status:", response.status);

      if (response.ok) {
        setLoading(false); // Stop loading spinner
        alert("Member request submitted successfully!");
        window.location.reload(); // Reload the page after submission (optional)
      } else {
        throw new Error("Failed to submit request");
      }
    } catch (err) {
      setLoading(false);
      alert("Error submitting member request. Please try again.");
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  function showAlert() {
    alert(
      "We are currently reviewing the Terms and Conditions. They will be shared with you via email shortly. Thank You!"
    );
  }

  return (
    <>
      <form action="" method="" onSubmit={handleSubmit}>
        <div className="my-10 flex items-center justify-center ">
          <div className="mx-1 sm:mx-4 rounded-xl relative h-[2000px] sm:h-[1900px] w-full">
            {/* Blurhash as a placeholder */}
            {/* Blurhash as a placeholder */}
            {!imageLoaded && (
              <Blurhash
                hash="[18g.@=9$u_24=OINO9G95%EDg9bRgxA_0x]?]sjx[o#$^RkS*oyZ~j_RQM{IDbFaMWrROWUt6tP"
                width="100%"
                height="100%"
                resolutionX={32}
                resolutionY={32}
                punch={1}
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
              />
            )}

            {/* The actual image that fades in when loaded */}
            {imageLoaded && (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e"
                alt="Join Us Background"
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                style={{ opacity: 0, transition: "opacity 0.5s" }}
                onLoad={(e) => (e.target.style.opacity = 1)}
              />
            )}

            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-xl">
              {/* contents */}
              <div className="flex-col justify-center items-center mx-2">
                <div id="target-section">
                  <center>
                    <h1 className="text-lg md:text-3xl text-white font-semibold antialiased font-sans mb-6 text-center">
                      Join Us
                    </h1>
                  </center>
                </div>
                <div className="flex w-72 sm:w-full ">
                  <div className="space-y-4">
                    {/* Name Fields */}
                    <div className="sm:flex sm:space-x-2 items-center justify-between">
                      <div className="flex-row justify-center h-full">
                        <p className="text-white ">
                          First Name
                          <span className="text-red-500">*</span>
                        </p>
                        <input
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          style={{ border: "1px solid" }}
                          className="text-left px-3 py-2 w-72 sm:w-60 w h-12 bg-transparent border border-white text-white rounded-xl"
                          required
                        />
                      </div>
                      <div>
                        <p className="text-white">
                          Surname <span className="text-red-500">*</span>
                        </p>
                        <input
                          type="text"
                          name="surName"
                          value={surName}
                          onChange={(e) => setSurName(e.target.value)}
                          style={{ border: "1px solid" }}
                          className="text-left px-3 py-2 h-12 w-72 sm:w-60 bg-transparent border border-white text-white rounded-xl"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex-col">
                      {/* Email */}
                      <div>
                        <p className="text-white mt-2">
                          Email <span className="text-red-500">*</span>
                        </p>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ border: "1px solid" }}
                          className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                          required
                        />
                      </div>
                      {emailError && (
                        <p className="text-red-500">{emailError}</p>
                      )}

                      {/* Step 1: Send Verification Email */}
                      {!codeSent && (
                        <button
                          type="button"
                          onClick={sendVerificationEmail}
                          disabled={isVerificationButtonDisabled || loading} // Disable the button after clicking
                          className="mt-1 rounded-lg p-2 bg-blue-200 flex items-center justify-center"
                        >
                          {loading ? (
                            <svg
                              className="animate-spin h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                              ></path>
                            </svg>
                          ) : isVerificationButtonDisabled ? (
                            "Verification Sent"
                          ) : (
                            "Send Verification Code"
                          )}
                        </button>
                      )}

                      {/* Step 2: Verify the code */}
                      {codeSent && !emailVerified && (
                        <>
                          <input
                            type="text"
                            placeholder="Enter verification code"
                            value={enteredCode}
                            onChange={(e) => setEnteredCode(e.target.value)}
                            className="mt-1 rounded-lg p-1 mr-2"
                          />

                          <button
                            type="button"
                            onClick={verifyCode}
                            disabled={isVerificationButtonDisabled || loading} // Disable the button after clicking
                            className=" my-1 bg-blue-500 py-1 px-2 rounded-lg text-white"
                          >
                            {loading && (
                              <svg
                                className="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                              </svg>
                            )}
                            <button type="button">Verify Email</button>
                          </button>

                          {/* {loading ? (
                            <div></div>
                          ) : (

                          )} */}
                        </>
                      )}

                      {/* If the code doesn't match, show the error and the option to resend */}
                      {codeMismatch && (
                        <div>
                          <p className="text-red-500">{errorMessage}</p>
                          <button
                            type="button"
                            onClick={() => {
                              setEnteredCode(""); // Clear entered code
                              setCodeSent(false); // Reset the state to allow resending
                              setIsVerificationButtonDisabled(false); // Enable the resend button
                              sendVerificationEmail(); // Resend the email
                              setCodeMismatch(false);
                            }}
                            className="mt-2 bg-yellow-500 text-white p-2 rounded-lg"
                          >
                            Resend Verification Email
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Contact, DOB */}
                    <div className="sm:flex sm:space-x-2 sm:justify-between items-center">
                      <div>
                        <p className="text-white mt-2">
                          Contact <span className="text-red-500">*</span>
                        </p>
                        <input
                          type="tel"
                          name="contact"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          style={{ border: "1px solid" }}
                          className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                          required
                        />
                      </div>
                      <div>
                        <p className="text-white mt-2">
                          Date of Birth <span className="text-red-500">*</span>
                        </p>
                        <input
                          type="date"
                          name="dob"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          style={{ border: "1px solid" }}
                          className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      {/* Gender */}
                      <p className="text-white mt-2">
                        Gender <span className="text-red-500">*</span>
                      </p>
                      <div className="flex items-center">
                        <label className="text-white mr-4">
                          <input
                            type="radio"
                            name="gender"
                            value="Male"
                            onChange={(e) => setGender(e.target.value)}
                            className="mr-2"
                            required
                          />
                          Male
                        </label>
                        <label className="text-white">
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            onChange={(e) => setGender(e.target.value)}
                            className="mr-2"
                            required
                          />
                          Female
                        </label>
                      </div>
                    </div>

                    <div>
                      {/* Location */}
                      <p className="text-white mt-2">
                        Location <span className="text-red-500">*</span>
                      </p>
                      <input
                        type="text"
                        name="location"
                        placeholder="eg. Kwabenya - Accra"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        style={{ border: "1px solid" }}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      />
                    </div>

                    <div>
                      {/* Address */}
                      <p className="text-white mt-2">
                        Address <span className="text-red-500">*</span>
                      </p>
                      <input
                        type="text"
                        name="address"
                        placeholder="eg. GE-234-434"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{ border: "1px solid" }}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      />
                    </div>

                    <div>
                      {/* Education Level */}
                      <p className="text-white mt-2">
                        Education Level<span className="text-red-500">*</span>
                      </p>
                      <select
                        name="educationLevel"
                        value={educationLevel}
                        onChange={(e) => setEducationLevel(e.target.value)}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      >
                        <option value="" disabled>
                          Select your education level
                        </option>
                        <option
                          value="Junior High School"
                          className="text-black"
                        >
                          {" "}
                          Junior High School
                        </option>
                        <option
                          value="Senior High School"
                          className="text-black"
                        >
                          {" "}
                          Senior High School
                        </option>
                        <option value="Diploma" className="text-black">
                          Diploma
                        </option>
                        <option value="Bachelors" className="text-black">
                          Bachelors
                        </option>
                        <option value="Masters" className="text-black">
                          Masters
                        </option>
                        <option value="Phd" className="text-black">
                          PhD
                        </option>
                        <option value="Other" className="text-black">
                          Other
                        </option>
                      </select>
                    </div>

                    <div>
                      {/* Institution */}
                      <p className="text-white mt-2">
                        Institution ( If other Specify )
                        <span className="text-red-500">*</span>
                      </p>
                      <input
                        type="text"
                        name="institution"
                        placeholder="Enter your institution"
                        value={institution}
                        onChange={(e) => setInstitution(e.target.value)}
                        style={{ border: "1px solid" }}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      />
                    </div>

                    <div>
                      <div>
                        <p className="text-white mt-2">
                          Select Your Interests (Max 3){" "}
                          <span className="text-red-500">*</span>
                        </p>
                        <select
                          value={""} // Set value to empty string since the dropdown doesn't need to show multiple selected values
                          onChange={handleInterestChange}
                          className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        >
                          {interestOptions.map((interest) => (
                            <option
                              key={interest}
                              value={interest}
                              className="text-black"
                            >
                              {interest}
                            </option>
                          ))}
                        </select>

                        {/* Display selected interests with an "x" to remove */}
                        <div className="text-white mt-2">
                          <p>Selected Interests:</p>
                          {stemInterest.map((interest, index) => (
                            <div
                              key={index}
                              className="inline-flex items-center mr-2 mb-1 px-2 py-1 bg-gray-700 rounded-full"
                            >
                              <span>{interest}</span>
                              <button
                                type="button"
                                onClick={() => removeInterest(interest)} // Call removeInterest to remove the selected item
                                className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                              >
                                &times;
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Previous Experience */}
                      <p className="text-white mt-2">
                        Previous Experience (If any)
                      </p>
                      <textarea
                        id="multiline-input"
                        rows={4}
                        className="text-left w-full h-28 px-3 py-2 border text-white object-cover bg-opacity-20 bg-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={previousExperience}
                        onChange={(e) => setPreviousExperience(e.target.value)}
                      />
                    </div>

                    <div>
                      {/* Emergency Contact */}
                      <p className="text-white mt-2">
                        Emergency Contact{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <input
                        type="tel"
                        name="emergencyContact"
                        value={emergencyContact}
                        onChange={(e) => setEmergencyContact(e.target.value)}
                        style={{ border: "1px solid" }}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      />
                    </div>

                    <div>
                      {/* Skills/Talents */}
                      <p className="text-white mt-2">Skills/Talents</p>
                      <input
                        type="text"
                        name="skillsTalents"
                        value={skillsTalents}
                        onChange={(e) => setSkillsTalents(e.target.value)}
                        style={{ border: "1px solid" }}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                      />
                    </div>

                    <div>
                      <p className="text-white mt-2">
                        Preferred Communication Method
                        <span className="text-red-500">*</span>
                      </p>
                      <select
                        name="communicationMethod"
                        value={communicationMethod}
                        onChange={(e) => setCommunicationMethod(e.target.value)}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="WhatsApp" className="text-black">
                          WhatsApp
                        </option>
                        <option value="Facebook" className="text-black">
                          Facebook
                        </option>
                        <option value="LinkedIn" className="text-black">
                          LinkedIn
                        </option>
                        <option value="Instagram" className="text-black">
                          Instagram
                        </option>
                        <option value="Email" className="text-black">
                          Email
                        </option>
                        {/* Add more options if needed */}
                      </select>
                    </div>

                    <div>
                      <p className="text-white mt-2">
                        How did you hear of us?{" "}
                        <span className="text-red-500">*</span>
                      </p>
                      <select
                        name="howDidYouHearOfUs"
                        value={howDidYouHearOfUs}
                        onChange={(e) => setHowDidYouHearOfUs(e.target.value)}
                        className="text-left w-full px-3 py-2 h-12 bg-transparent border border-white text-white rounded-xl"
                        required
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {/* Specific social media options */}
                        <option value="WhatsApp" className="text-black">
                          WhatsApp
                        </option>
                        <option value="Facebook" className="text-black">
                          Facebook
                        </option>
                        <option value="LinkedIn" className="text-black">
                          LinkedIn
                        </option>
                        <option value="Instagram" className="text-black">
                          Instagram
                        </option>
                        {/* Other options */}
                        <option value="Friend" className="text-black">
                          Friend
                        </option>
                        <option value="Colleague" className="text-black">
                          Colleague
                        </option>
                        <option
                          value="SEEDAfrique Member"
                          className="text-black"
                        >
                          SEEDAfrique Member
                        </option>
                        <option
                          value="SEEDAfrique Executive"
                          className="text-black"
                        >
                          SEEDAfrique Executive
                        </option>
                      </select>
                    </div>

                    <div>
                      {/* Why Join */}
                      <p className="text-white mt-2 w-64 sm:w-72">
                        How do you hope to contribute or benefit from
                        SEEDAfrique? <span className="text-red-500">*</span>
                      </p>
                      <textarea
                        id="multiline-input"
                        rows={4}
                        className="text-left w-full h-28 px-3 py-2 border text-white object-cover bg-opacity-20 bg-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={whyJoinSeedafrique}
                        onChange={(e) => setWhyJoinSeedafrique(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-7">
                  <div className="flex items-center justify-center">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="checkbox"
                          className="mr-2 h-6 w-6 rounded border-gray-300"
                          checked={acceptedTerms}
                          onChange={(e) => setAcceptedTerms(e.target.checked)}
                          required
                        />
                      </div>
                      <label
                        htmlFor="checkbox"
                        className="mr-4 text-white text-base font-semibold"
                      >
                        I accept the{" "}
                        <button
                          onClick={showAlert}
                          type="button"
                          className="underline"
                        >
                          Terms
                        </button>
                      </label>
                    </div>

                    {/* Step 3: Proceed to submit form after verification */}
                    <button
                      type="submit"
                      disabled={loading || !emailVerified} // Disable button when loading
                      className="bg-buttonColor text-xs p-3 px-3 rounded-md hover:bg-green-600"
                    >
                      {loading ? (
                        <div className="flex items-center">
                          <svg
                            className="animate-spin h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
                {!emailVerified && (
                  <div className="bg-red-200 py-1 my-3 rounded">
                    {" "}
                    <p className="text-center text-red-700">
                      {" "}
                      Email not verified{" "}
                    </p>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
