import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiTeamLine } from "react-icons/ri";
import { TiThMenu } from "react-icons/ti";
import { TbWorldWww } from "react-icons/tb";
import {
  FaBlog,
  FaImages,
  FaCalendarAlt,
  FaUserFriends,
  FaHome,
  FaChartPie,
} from "react-icons/fa"; // Import additional icons as needed

// Sidebar icon data
const sidebarItems = [
  {
    to: "/admin/admindashboard-content/manage-blogs",
    icon: <FaBlog size={22} />,
    label: "Blog",
  },
  {
    to: "/admin/admindashboard-content/manage-gallery",
    icon: <FaImages size={22} />,
    label: "Gallery",
  },
  {
    to: "/admin/admindashboard-content/manage-events",
    icon: <FaCalendarAlt size={22} />,
    label: "Events",
  },
  {
    to: "/admin/admindashboard-content/manage-requests",
    icon: <FaUserFriends size={22} />,
    label: "Requests",
  },
  {
    to: "/admin/admindashboard-content/manage-team",
    icon: <RiTeamLine size={22} />,
    label: "Team",
  },
  {
    to: "/admin/admindashboard",
    icon: <FaChartPie size={22} />,
    label: "Dashboard",
  },
];

// Reusable sidebar icon component
const SidebarIcon = ({ to, icon, onClick }) => (
  <Link to={to} onClick={onClick}>
    <div className="w-12 h-12 border-4 bg-[#e5ecda] text-green-950 mb-1 border-transparent hover:border-blue-500 transition-all duration-300 rounded-xl flex items-center justify-center">
      {icon}
    </div>
  </Link>
);

const AdminSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      {/* Toggle button for small screens */}
      <div className="sm:hidden fixed bottom-64 left-5 z-40">
        <button onClick={toggleSidebar}>
          <div className="flex items-center justify-center w-10 h-10 border-2 bg-blue-500 border-white rounded-xl p-1">
            <TiThMenu className="text-white" size={24} />
          </div>
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed sm:block sm:left-2 top-2 h-auto p-3 transition-transform transform ${
          isSidebarOpen ? "translate-x-0 left-2" : "-translate-x-full"
        } sm:translate-x-0 z-50 bg-buttonColor rounded-3xl`}
        onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside
      >
        <div className="flex flex-col justify-between h-full">
          {/* Top icons */}
          <div className="space-y-4">
            {sidebarItems.map((item, index) => (
              <SidebarIcon
                key={index}
                to={item.to}
                icon={item.icon}
                onClick={closeSidebar}
              />
            ))}
          </div>

          {/* Bottom icon */}
          <Link
            to="/home"
            className="mt-9 rounded-xl w-12 h-12 bg-green-800 border-4 border-transparent hover:border-blue-500 transition-all duration-300 flex items-center justify-center"
            onClick={closeSidebar}
          >
            <TbWorldWww className="text-white" size={23} />
          </Link>
        </div>
      </div>

      {/* Overlay for small screens */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-75 sm:hidden z-40"
          onClick={closeSidebar}
        ></div>
      )}
    </>
  );
};

export default AdminSidebar;
