import React, { useState } from "react";

// Needed for the join us button navigation.
import { useNavigate } from "react-router-dom";
import OurTeamSection from "../../components/aboutpagecomponents/OurTeamSection.js";
import OurTeamSwiperData from "../../data/OurTeamData.js";
import missionImg from "../../images/aboutpgimgs/Micheal viewing the photo finish.jpg";
import vissionImg from "../../images/aboutpgimgs/Uncle Billy and the holychild runner .jpg";

// Importing the core values and goals from SeedAfriqueOrganization.js
import CoreValuesAndGoals from "../../components/homepageComponents/CoreValuesAndGoals.js";
import Team from "./Team.js";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useSwiper } from "swiper/react";

import "swiper/swiper-bundle.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Blurhash } from "react-blurhash";

// Out team Data Array

const About = () => {
  return (
    <>
      <AboutDeskView />
      <AboutMobiView />
    </>
  );
};

export default About;

//FUNCTIONAL COMPONENTS

// About page desktop view
const AboutDeskView = () => {
  const navigate = useNavigate();

  const handleJoinUsClick = () => {
    navigate("/joinus", { state: { targetSection: "join-us-section" } }); // Pass the section ID to navigate
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const missionhash = "";

  return (
    <>
      <div className="hidden sm:block mb-16">
        {/* TITLE Section */}
        <div className="bg-aboutbg py-12 my-7">
          <p className="text-center font-bold text-3xl"> ABOUT US</p>
          <p className="text-center text-lg mt-5 font-Poppins">
            The Organization develops and harnesses the young minds <br /> of
            the growing Youth in Ghana and Africa. Our aim is to <br />{" "}
            stimulate the interest of the Youth in Engineering and other <br />{" "}
            STEM-related fields by connecting them to industries of their <br />{" "}
            specialization to further build and hone their skills and <br />{" "}
            talents. Membership is open to young people from the Junior <br />{" "}
            through Senior High School level (K-12) to the graduate/ <br />{" "}
            early career level.
          </p>
        </div>

        {/* mission */}
        <div className="flex justify-center px-11 py-5 space-x-10 mb-8 ">
          {/* missiom text */}
          <div className=" flex items-center w-1/2 ">
            <div className=" ">
              <p className="font-bold text-3xl font-Poppins">Mission </p>

              <p className="mt-2 text-lg font-Poppins">
                Our Mission is to lead the African youth through STEM Education
                for viable opportunities. Strategic Engineering Experience
                DriveAfrique (SEEDAfrique) is an <br /> organisation that
                connects individuals <br /> with STEM Interest for growth.
              </p>
            </div>
          </div>

          {/* blur hash for the mission image */}
          {!imageLoaded && (
            <div className="flex justify-center items-center h-96 rounded-xl overflow-hidden">
              <Blurhash
                hash="VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                height={"100%"}
                width={500}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}

          {/* mission image */}
          <div
            className={`flex justify-center items-center h-96 ${
              imageLoaded ? "" : "hidden"
            }`}
            style={{ width: "500px" }}
          >
            <img
              src={missionImg}
              className="h-full w-full object-cover rounded-xl"
              onLoad={() => setImageLoaded(true)}
            />
          </div>
        </div>

        {/* vision */}
        <div className="flex justify-center space-x-10 bg-aboutbg py-12 sm:px-7">
          {/* Blur hash for the vision - Desktop */}
          {!imageLoaded && (
            <div className="flex justify-center items-center h-96 rounded-xl overflow-hidden">
              <Blurhash
                hash="VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                width={500}
                height={"100%"}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}

          {/* vision img */}
          <div
            className={`flex justify-center items-center h-96 ${
              imageLoaded ? "" : " hidden "
            }`}
            style={{ width: "500px" }}
          >
            <img
              src={vissionImg}
              className="h-full w-full object-cover rounded-xl"
              onLoad={() => setImageLoaded(true)}
            />
          </div>
          {/* vision text */}
          <div className="flex items-center">
            <div className="space-y-6">
              <p className="font-bold text-3xl font-Poppins">Vision</p>
              <p className="text-lg font-Poppins">
                Our Vision is to create a pool of <br /> dynamic STEM experts
                for infrastructural <br /> and socio-economic growth in Africa.
              </p>
              {/* The Join Us button */}
              <button
                className="bg-buttonColor text-md p-1 px-3 rounded-xl hover:bg-green-600"
                onClick={handleJoinUsClick}
              >
                Join Us
              </button>
            </div>
          </div>
        </div>

        {/* core values and gosls */}
        <Cvg />

        {/* our team */}
        <div>
          {/* title text  */}
          <p className="font-bold text-3xl font-Poppins text-center mt-5">
            {" "}
            Our Team
          </p>

          {/* the body of the out teams section*/}
          <div id="our-icon-section">
            <Team />
          </div>
        </div>
      </div>
    </>
  );
};

// About page Mobile view
const AboutMobiView = () => {
  //Helps in the navigation
  const navigate = useNavigate();

  //  "/" navigates it to the join us part of the home page.
  const handleJoinUsClick = () => {
    navigate("/joinus", { state: { targetSection: "join-us-section" } }); // Pass the section ID to navigate
  };

  // setting the useState for the blur hash logic on mobile
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <div className="sm:hidden visible">
        {/* TITLE Section */}
        <div className="bg-aboutbg py-9 sm:py-10 mt-7">
          <p className="text-center font-Poppins font-bold text-lg sm:text-xl">
            {" "}
            ABOUT US
          </p>
          <p className="text-center text-sm sm:text-base mt-5 font-Poppins px-2 sm:px-5">
            The Organization develops and harnesses the young minds of the
            growing Youth in Ghana and Africa. Our aim is to stimulate the
            interest of the Youth in Engineering and other STEM-related fields
            by connecting them to industries of their specialization to further
            build and hone their skills and talents. Membership is open to young
            people from the Junior through Senior High School level (K-12) to
            the graduate/ early career level.
          </p>
        </div>

        {/* mission */}
        <div className=" justify-center px-2 py-7 sm:px-11 sm:py-5 ">
          {/* missiom text */}

          <p className="font-bold text-lg sm:text-xl font-Poppins text-center">
            Mission{" "}
          </p>

          <p className="mt-2 text-sm sm:text-lg font-Poppins text-center">
            Our Mission is to lead the African youth
            <br /> through STEM Education for viable
            <br /> opportunities.
            <br />
            <br /> Strategic Engineering Experience
            <br /> DriveAfrique (SEEDAfrique) is an <br /> organisation that
            connects individuals <br /> with STEM Interest for growth.
          </p>

          {/* mobile mission blurhash */}
          {!imageLoaded && (
            <div className="flex justify-center items-center h-56 mt-2 sm:mt-0 overflow-hidden rounded-xl">
              <Blurhash
                hash="VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                height={"100%"}
                width={"100%"}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
              {/* In case blur hash hadnt been the did below would have neen what we would have used to mimic the loading state */}
              {/* <div className="bg-slate-700 h-full w-full" ></div> */}
            </div>
          )}

          {/* mission image */}
          <div
            className={`flex justify-center items-center h-56 mt-2 sm:mt-0 ${
              imageLoaded ? "" : "hidden"
            }`}
          >
            <img
              src={missionImg}
              className="h-full w-96 object-cover rounded-xl"
              // Dont forget the "() =>" when using the useState hook
              onLoad={() => setImageLoaded(true)}
            />
          </div>
        </div>

        {/* vision */}
        <div className=" justify-center mt-5 bg-aboutbg py-9 px-2 sm:py-7 sm:px-11">
          {/* Vission Blurhash */}

          {!imageLoaded && (
            <div className="flex justify-center items-center h-56 mt-2 sm:w-96 sm:mt-0 overflow-hidden rounded-xl">
              <Blurhash
                hash="VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                height={"100%"}
                width={"100%"}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}
          {/* vision img */}
          <div
            className={`flex justify-center items-center h-56 mt-2 sm:mt-0 ${
              imageLoaded ? "" : "hidden"
            } `}
          >
            <img
              src={vissionImg}
              className="h-full w-full sm:w-96 object-cover rounded-xl"
              onLoad={() => setImageLoaded(true)}
            />
          </div>
          {/* vision text */}

          <p className="font-bold text-lg sm:text-xl font-Poppins mt-3 sm:mt-6 text-center">
            Vision
          </p>
          <p className="text-sm sm:text-lg font-Poppins mt-2 sm:mt-6 text-center">
            Our Vision is to create a pool of <br /> dynamic STEM experts for
            infrastructural <br /> and socio-economic growth in Africa.
          </p>
          {/* The Join Us button */}
          <div className="flex items-center justify-center">
            {/* This tells the button what to do when the button is clicked */}
            <button
              onClick={handleJoinUsClick}
              className="bg-buttonColor text-md p-1 px-3 rounded-xl hover:bg-green-600 mt-2 sm:mt-6 text-center"
            >
              Join Us
            </button>
          </div>
        </div>

        <Cvg />

        {/* title text  */}
        <p className="font-bold text-lg font-Poppins text-center mt-3">
          {" "}
          Our Team
        </p>
        <div id="our-icon-section">
          <Team />
        </div>
      </div>
    </>
  );
};

const Cvg = () => {
  return (
    <>
      <div className="mt-10">
        <CoreValuesAndGoals />
      </div>
    </>
  );
};

const OurTeamSwiper = () => {
  const swiper = useSwiper();
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation={{
        clickable: true,
      }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      modules={[Pagination, Navigation]}
    >
      {OurTeamSwiperData.map((data, index) => (
        <SwiperSlide key={index}>
          {/*  Desktop Version */}
          <div className="hidden sm:block">
            <div className="flex justify-center items-center mt-5">
              <div
                className="flex justify-center items-center bg-aboutbg h-96 space-x-10 rounded-xl px-14"
                style={{ width: "600px" }}
              >
                <div className="flex justify-center items-center h-56 w-48">
                  <img
                    src={data.image}
                    className="h-full w-full object-cover rounded-xl"
                  />
                </div>
                <div className="w-1/2 bg-buttonColor p-3 rounded-xl">
                  <p className="font-Poppins  font-extrabold">{data.name}</p>
                  <p className="font-Poppins  font-semibold">{data.position}</p>
                  <p className="font-Poppins  ">{data.about}</p>
                </div>
              </div>
            </div>
          </div>

          {/*  Mobile Version */}
          <div className="sm:hidden visible">
            <div className="flex justify-center items-center mt-5 px-2">
              <div
                className="flex flex-col justify-center items-center bg-aboutbg space-y-4 rounded-xl"
                style={{ height: "450px" }}
              >
                <div className="flex justify-center items-center ">
                  <img
                    src={data.image}
                    className="h-48 w-56 object-cover rounded-xl"
                  />
                </div>
                <div className=" bg-buttonColor p-3 rounded-xl mx-3">
                  <p className="font-Poppins text-sm font-extrabold">
                    {data.name}
                  </p>
                  <p className="font-Poppins text-sm font-semibold">
                    {data.position}
                  </p>
                  <p className="font-Poppins text-xs whitespace-pre-line">
                    {data.about}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

// commit save
// Commit save 2
