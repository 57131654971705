"use client";
import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";
// Needed for the join us button navigation.
import { Link, useNavigate } from "react-router-dom";

// Icons
import { IoHome } from "react-icons/io5"; //Home
import { GrServices } from "react-icons/gr"; //Services
import { FaHandshake } from "react-icons/fa"; // Join Us
import { FaCircleInfo } from "react-icons/fa6"; //info
import { FaBlog } from "react-icons/fa"; //blog
import { GrGallery } from "react-icons/gr"; //gallery
import { IoPeopleSharp } from "react-icons/io5"; //People
import { FaQuestionCircle } from "react-icons/fa"; //faqs
import { MdEmail } from "react-icons/md"; // Email
import { FaPhone } from "react-icons/fa"; //Phone
import { FaMapLocationDot } from "react-icons/fa6"; //Location
import { FaFacebook } from "react-icons/fa"; //FaCEBOOK
import { FaInstagram } from "react-icons/fa6"; //instagram
import { FaXTwitter } from "react-icons/fa6"; // twitter
import { FaLinkedin } from "react-icons/fa"; // linkedin

const iconsize = {
  Desktopandtablets: "md:h-9 md:w-9 lg:h-11 lg:w-11",
};

export default function Footer() {
  return (
    <>
      {/* footer top */}
      <FooterTop />

      {/* bottom */}
      <FooterBottom />
    </>
  );
}

// COMPONENTS

// Top Part of the footer
const FooterTop = () => {
  return (
    <>
      {/* for desktop only */}
      <div className="hidden sm:block">
        <div className=" sm:flex sm:justify-between sm:px-12 sm:py-9 sm:bg-deeperpalegreen ">
          {/* Our Newletters  */}
          <div className="pr-4 flex items-center ">
            <div>
              <h1 className=" text-3xl text-emerald-900 font-semibold antialiased font-sans mb-2">
                {" "}
                Our Newsletter{" "}
              </h1>
              <p className="text-emerald-900 antialiased font-sans ">
                {" "}
                Join our newsletter to stay up to date on features and releases.{" "}
              </p>
              <p className="text-emerald-900 antialiased font-sans ">
                {" "}
                By subscribing, you agree to our privacy policy and consent to
                <br></br> receive updates from our organization.{" "}
              </p>
            </div>
          </div>

          {/* subscribe to our newsletters  */}
          <form>
            <div className=" flex items-center ">
              <div className=" justify-center mt-6 ">
                <input
                  type="email"
                  placeholder="Enter your email"
                  style={{ border: "2px solid" }}
                  className="text-left sm:mb-2 w-32 sm:w-96 px-3 py-2 mr-3 bg-transparent border-2 border-emerald-900 text-emerald-900 rounded-xl"
                  required
                />
                <div>
                  <input
                    type="submit"
                    className="bg-buttonColor text-xs p-1 px-3 rounded-full w-16 hover:bg-green-600"
                  />
                </div>
              </div>
            </div>
          </form>

          {/* logo at the end */}
          <div className="flex items-center">
            <img
              src="../logo/SeedAfrique Org Logo.png"
              alt="Logo"
              className="h-24 w-24"
            ></img>
          </div>
        </div>

        <div></div>
      </div>

      {/* for mobile */}

      <div className="sm:hidden">
        <div className=" px-4 py-9 bg-deeperpalegreen mt-14 overflow-y-auto">
          {/* our Newsletter heading  */}

          <div>
            <center>
              <h1 className=" text-3xl text-emerald-900 font-semibold antialiased font-sans mt-3 ">
                Our Newletter
              </h1>
            </center>
          </div>
          {/* <center><span className=" text-3xl text-emerald-900 font-semibold antialiased font-sans mb-2">Our Newsletters</span> </center> */}

          {/* text and logo  */}
          <div className="flex ">
            <div className="flex items-center">
              <p className="text-emerald-900 antialiased text-xs font-sans ">
                {" "}
                Join our newsletter to stay up to date on features and releases.{" "}
              </p>
              {/* <p className="text-emerald-900 antialiased font-sans "> By subscribing you agree to our privacy policy and provide consent to <br></br> recieve updates from our organization. </p> */}
            </div>
            <div className="flex items-center">
              <img
                src="../logo/SeedAfrique Org Logo.png"
                alt="Logo"
                className="h-auto w-40 "
              ></img>
            </div>
          </div>

          {/* textbox and button  */}
          <form>
            <div className=" flex justify-between ">
              <input
                type="email"
                placeholder="Enter your email"
                style={{ border: "2px solid" }}
                className="w-full mr-5 px-3 py-2 text-left bg-transparent border-2 border-emerald-900 text-emerald-900 rounded-xl"
                required
              />
              <div className="flex items-center">
                <input
                  type="submit"
                  className="bg-buttonColor text-xs h-10 w-16 text-center rounded-full hover:bg-green-600"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// Bottom part of the footer - The Part with the icons
const FooterBottom = () => {
  return (
    <>
      {/* Desktop devices only  */}
      <FooterBottomDesktop />

      {/* for mobile only  */}
      <FooterBottomMobile />
    </>
  );
};

// Footer Bootom Desktop
const FooterBottomDesktop = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {/* The bottom footer  */}
      <div className=" hidden md:block relative">
        {imageLoaded ? (
          <img
            className="w-full h-[390px] object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FTime%20with%20taishun%20background.png?alt=media&token=d6ddeca6-a223-4277-9a34-bea71c342d43"
          />
        ) : (
          <div className="w-full h-[390px]">
            <Blurhash
              hash="[78D;IMyQ-s,?ws9M_jFF5xsS#RkkYWqkCWn%2WYaKoffNozofflRiWqbbWqV@bHbGa#Rjs.j[ag"
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}

        <div className="absolute inset-0">
          {/* part on top of the founder  */}
          <div className=" flex items-center justify-between mx-5 mb-16 pt-16">
            {/* far left text  */}
            <div className="flex items-center w-1/4">
              <div className="space-y-5">
                <p className="text-white antialiased font-sans mt-4">
                  {" "}
                  Fully Active onn all social media Platforms Follow us to
                  always be up to date with our services <br />
                  Tap on the icon to connect{" "}
                </p>

                <div className="flex space-x-3">
                  <a
                    href="https://www.facebook.com/seedafrique?mibextid=LQQJ4d"
                    alt="Our Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaFacebook
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/seedafrique?igsh=MXI4ZXIycmZqbzZjdA=="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaInstagram
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>

                  <a
                    href="https://x.com/seedafrique?s=21"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaXTwitter
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/seedafrique/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaLinkedin
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>

            {/* navigation */}
            <div>
              <h1 className=" lg:text-3xl md:text-xl text-white font-semibold antialiased font-sans mb-5">
                {" "}
                Navigations{" "}
              </h1>
              <div className="space-y-3">
                <Link to="/home">
                  <a href="">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <IoHome
                          className="h-6 w-6  "
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                        {" "}
                        HOME{" "}
                      </p>
                    </div>
                  </a>
                </Link>
                <div>
                  <Link to="/joinus#target-section">
                    <a href="">
                      <div className="flex items-center">
                        <div className="bg-paleGreen p-1 rounded-md">
                          <FaHandshake
                            className="h-6 w-6"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                          {" "}
                          JOIN US{" "}
                        </p>
                      </div>
                    </a>
                  </Link>
                </div>
                <div>
                  <Link to="/about">
                    <a href="">
                      <div className="flex items-center">
                        <div className="bg-paleGreen p-1 rounded-md">
                          <FaCircleInfo
                            className="h-6 w-6"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                          ABOUT US
                        </p>
                      </div>
                    </a>
                  </Link>
                </div>

                <div className="flex items-center">
                  <div className="bg-paleGreen p-1 rounded-md">
                    <GrServices
                      className="h-6 w-6"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                    SERVICES
                  </p>
                </div>
              </div>
            </div>

            {/* quicklinks */}
            <div>
              <h1 className="md:text-xl lg:text-3xl text-white font-semibold antialiased font-sans mb-5">
                {" "}
                Quick Links{" "}
              </h1>
              <div className="space-y-3">
                <div>
                  <Link to="/blog">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <FaBlog
                          className="h-6 w-6"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                        {" "}
                        BLOGS{" "}
                      </p>
                    </div>
                  </Link>
                </div>

                <div>
                  <Link to="gallery">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <GrGallery
                          className="h-6 w-6"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                        {" "}
                        GALLERY{" "}
                      </p>
                    </div>
                  </Link>
                </div>

                <div>
                  <Link to="/about#our-icon-section">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <IoPeopleSharp
                          className="h-6 w-6"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                        {" "}
                        OUR ICONS{" "}
                      </p>
                    </div>
                  </Link>
                </div>

                <div className="flex items-center">
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaQuestionCircle
                      className="h-6 w-6"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans">
                    {" "}
                    FAQs{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* contact us  */}
            <div>
              <h1 className="md:text-xl lg:text-3xl text-white font-semibold antialiased font-sans mb-5">
                {" "}
                Contact Us{" "}
              </h1>
              <div className="space-y-3">
                <div className="flex items-center">
                  <div
                    className="bg-paleGreen p-1 rounded-md"
                    onClick={() =>
                      (window.location.href = "mailto:seedafrique1@gmail.com")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <MdEmail className="h-6 w-6" />
                  </div>
                  <p
                    className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans"
                    onClick={() =>
                      (window.location.href = "mailto:seedafrique1@gmail.com")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    EMAIL
                  </p>
                </div>

                <div className="flex items-center">
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaPhone
                      className="h-6 w-6"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        (window.location.href = "tel:+233208359694")
                      }
                    />
                  </div>
                  <p
                    className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "tel:+233208359694")}
                  >
                    {" "}
                    PHONE{" "}
                  </p>
                </div>

                <div className="flex items-center">
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaMapLocationDot
                      className="h-6 w-6"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://maps.app.goo.gl/qU6uuPJEpHUYCZ99A?g_st=ic",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <p
                    className="md:text-sm lg:text-lg ml-2 text-white font-semibold antialiased font-sans"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://maps.app.goo.gl/qU6uuPJEpHUYCZ99A?g_st=ic",
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    OFFICE{" "}
                  </p>
                </div>

                <button
                  className="bg-buttonColor text-sm mt-5 px-5 h-9 rounded-lg hover:bg-green-600"
                  style={{ cursor: "pointer" }}
                  onClick={() => (window.location.href = "tel:+233208359694")}
                >
                  {" "}
                  Call Us{" "}
                </button>
              </div>
            </div>
          </div>

          {/* founder at bottom */}
          <div className="">
            {/* white line  */}
            <div className="h-0.5 mx-5 bg-white"></div>
            <div className="flex justify-between">
              {/* left side  */}
              <div className="flex items-center text-white ml-5">
                &copy;
                <p className=" text-xs ">
                  {" "}
                  2024 SEEDafrique. Allrights reserved{" "}
                </p>
              </div>

              {/* right side  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Footer Bootom Mobile

const FooterBottomMobile = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  const handleJoinUsClick = () => {
    navigate("/", { state: { targetSection: "join-us-section" } }); // Pass the section ID to navigate
  };

  // Our icon button click handler
  const handleOurIconClick = () => {
    navigate("/about", { state: { targetSection2: "our-icon-section" } }); // Pass the section ID to navigate
  };

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once
  return (
    <>
      <div className=" visible md:hidden relative ">
        {imageLoaded ? (
          <img
            className="w-full h-[650px] object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FTime%20with%20taishun%20background.png?alt=media&token=d6ddeca6-a223-4277-9a34-bea71c342d43"
          />
        ) : (
          <div className="w-full h-[650px]">
            <Blurhash
              hash="[78D;IMyQ-s,?ws9M_jFF5xsS#RkkYWqkCWn%2WYaKoffNozofflRiWqbbWqV@bHbGa#Rjs.j[ag"
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}
        <div className="absolute inset-0 mt-5 px-2">
          <div className=" ml-3 ">
            {/* Navigations, Quicklinks and Contact  */}
            <div>
              {/* Navigation Heading */}
              <h1 className=" text-base text-white font-semibold antialiased font-sans mb-1">
                {" "}
                Navigation{" "}
              </h1>

              {/* Navigation Icons */}
              <div className=" space-y-2">
                <Link to="/home">
                  {/* Home Page icon */}
                  <div className="flex items-center ">
                    <div className="bg-paleGreen p-1 rounded-md">
                      <IoHome
                        className="h-5 w-5  "
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <p className="text-sm ml-1 text-white">Home</p>
                  </div>
                </Link>

                {/* Join Us Quick link */}
                <div>
                  <Link to="/joinus#target-section">
                    <a href="">
                      <div className="flex items-center">
                        <div className="bg-paleGreen p-1 rounded-md">
                          <FaHandshake
                            className="h-5 w-5"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <p className="text-sm ml-1 text-white">Join us</p>
                      </div>
                    </a>
                  </Link>
                </div>

                <div>
                  <Link to="./about">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <FaCircleInfo
                          className="h-5 w-5"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="text-sm ml-1 text-white">About Us</p>
                    </div>
                  </Link>
                </div>

                <div className="flex items-center">
                  <div className="bg-paleGreen p-1 rounded-md">
                    <GrServices
                      className="h-5 w-5"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="text-sm ml-1 text-white">Services</p>
                </div>
              </div>

              {/* Quick Lnks  */}
              <h1 className=" text-base text-white font-semibold antialiased font-sans mb-1 mt-5">
                {" "}
                Quick Links{" "}
              </h1>

              {/* Icons */}
              <div className="space-y-2">
                <div>
                  <Link to="/blog">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <FaBlog
                          className="h-5 w-5"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="text-white text-sm ml-1 "> Blog </p>
                    </div>
                  </Link>
                </div>

                <div>
                  <Link to="/gallery">
                    <div className="flex items-center">
                      <div className="bg-paleGreen p-1 rounded-md">
                        <GrGallery
                          className="h-5 w-5"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="text-white text-sm ml-1 "> Gallery </p>
                    </div>
                  </Link>
                </div>

                <div>
                  <Link to="/about#our-icon-section">
                    <div
                      className="flex items-center"
                      onClick={handleOurIconClick}
                    >
                      <div className="bg-paleGreen p-1 rounded-md">
                        <IoPeopleSharp
                          className="h-5 w-5"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <p className="text-white text-sm ml-1 "> Our Icons </p>
                    </div>
                  </Link>
                </div>

                <div className="flex items-center">
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaQuestionCircle
                      className="h-5 w-5"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p className="text-white text-sm ml-1 "> Faqs </p>
                </div>
              </div>

              {/* Contact Links  */}
              <h1 className=" text-base text-white font-semibold antialiased font-sans mb-1 mt-5">
                {" "}
                Contacts{" "}
              </h1>
              {/* Icons */}
              <div className="flex space-x-2 mr-16">
                <div className="bg-paleGreen p-1 rounded-md">
                  <MdEmail
                    className="h-5 w-5"
                    onClick={() =>
                      (window.location.href = "mailto:seedafrique1@gmail.com")
                    }
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <div className="bg-paleGreen p-1 rounded-md">
                  <FaPhone
                    className="h56 w-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "tel:+233208359694")}
                  />
                </div>

                <div className="bg-paleGreen p-1 rounded-md">
                  <FaMapLocationDot
                    className="h-5 w-5"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://maps.app.goo.gl/qU6uuPJEpHUYCZ99A?g_st=ic",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <button
                className="bg-buttonColor text-sm mt-5 px-5 h-9 rounded-lg hover:bg-green-600"
                style={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "tel:+233208359694")}
              >
                {" "}
                Call Us{" "}
              </button>
            </div>

            {/* Social media  */}
            <div>
              <p className="text-white text-xs mt-4">
                {" "}
                Stay connected with us on all social media platforms. <br /> Tap
                in to connect and keep up with the latest!{" "}
              </p>
              <div className="flex space-x-2 mt-3">
                {/* Facebook link  */}
                <a
                  href="https://www.facebook.com/seedafrique?mibextid=LQQJ4d"
                  alt="Our Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaFacebook
                      className="h-5 w-5"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </a>

                {/* instagram link */}
                <a
                  href="https://www.instagram.com/seedafrique?igsh=MXI4ZXIycmZqbzZjdA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaInstagram
                      className="h-5 w-5"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </a>

                {/* twitter link */}
                <a
                  href="https://x.com/seedafrique?s=21"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaXTwitter
                      className="h-5 w-5"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </a>

                {/* linkedin link */}
                <a
                  href="https://www.linkedin.com/company/seedafrique/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-paleGreen p-1 rounded-md">
                    <FaLinkedin
                      className="h-5 w-5"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Founder at bottom */}
          <div className="">
            {/* black line  */}
            <div className="h-0.5 mt-5 bg-white"></div>
            <div className="flex justify-between">
              {/* left side  */}
              <div className="flex items-center text-white">
                &copy;
                <p className=" text-xs">
                  {" "}
                  2024 SEEDafrique. All rights reserved{" "}
                </p>
              </div>

              {/* right side  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
