import React from "react";
import OurTeamSwiperData from "../../data/OurTeamData";

function Team() {
  return (
    <div className="flex overflow-x-auto font-Poppins space-x-4 px-4 mt-3 custom-scrollbar">
      {OurTeamSwiperData.map((teammember) => (
        <div
          key={teammember.id}
          className="flex-shrink-0 flex-col w-[280px] rounded-lg overflow-hidden"
        >
          <img
            src={teammember.image}
            className="w-full h-60 object-cover rounded-t-xl"
            alt={`${teammember.name}'s picture`}
          />
          <div className="bg-[#83CBFF] mt-2 px-2 rounded-md border-2 border-white">
            <h2 className="text-base font-semibold">{teammember.name}</h2>
          </div>
          <p className="text-sm mt-2 text-gray-600">{teammember.position}</p>
          <p className="text-sm mt-2 text-gray-500">{teammember.about}</p>
        </div>
      ))}
    </div>
  );
}

export default Team;
