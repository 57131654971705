import React, { useState } from "react";
import OurTeamSwiperData from "../../data/OurTeamData";

// Icons
import { CiEdit } from "react-icons/ci";
import { MdOutlinePersonRemove } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";
import { MdPersonSearch } from "react-icons/md";
import { BiFilter } from "react-icons/bi";
import ReactQuill from "react-quill";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";

function ManageTeam() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Handle opening the modal for adding a new member
  const handleAddMember = () => {
    setIsEditing(false); // Set to add mode
    setSelectedMember(null); // Clear any selected member
    setIsModalOpen(true); // Open modal
  };

  // Handle opening the modal for editing an existing member
  const handleEditMember = (member) => {
    setIsEditing(true); // Set to edit mode
    setSelectedMember(member); // Set the selected member to be edited
    setIsModalOpen(true); // Open modal
  };

  // Close the add/edit modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Open the search modal
  const openSearchModal = () => {
    setShowSearchModal(true);
  };

  // Close the search modal
  const closeSearchModal = () => {
    setShowSearchModal(false);
  };

  // Handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Confirm the search action
  const confirmSearch = () => {
    // Perform search action with searchTerm here if needed
    console.log("Searching for:", searchTerm);
    closeSearchModal(); // Close the search modal after confirming
  };

  return (
    <>
      <div className="my-5">
        <div className="flex flex-col font-Poppins space-y-4 px-4 mt-3">
          <div className="sm:flex sm:justify-center space-y-2 sm:space-y-0 sm:space-x-2">
            {/* Top icons */}
            <div className="flex sm:flex-col sm:justify-center items-center space-x-1 bg-slate-500 border-2 text-gray-200 border-white rounded-md p-2 w-fit sm:h-fit">
              {/* Add */}
              <div>
                <IoPersonAdd className="h-6 w-6" onClick={handleAddMember} />
              </div>

              {/* Search */}
              <div>
                <MdPersonSearch
                  className="h-7 w-7 text-sky-300 cursor-pointer"
                  onClick={openSearchModal}
                />
              </div>

              {/* Filter */}
              <div>
                <BiFilter className="h-7 w-7" />
              </div>
            </div>

            <div>
              {OurTeamSwiperData.map((teammember) => (
                <div
                  key={teammember.id}
                  className="flex-shrink-0 w-full max-w-md mx-auto rounded-lg overflow-hidden pb-5"
                >
                  <div className="relative">
                    <img
                      src={teammember.image}
                      className="w-full h-60 sm:h-72 object-cover rounded-t-xl"
                      alt={`${teammember.name}'s picture`}
                    />

                    {/* Remove and edit icon */}
                    <div className="absolute right-2 top-2 flex space-x-2">
                      <div
                        className="p-1 bg-gray-500 rounded-md border-2 text-white border-white"
                        onClick={() => handleEditMember(teammember)}
                      >
                        <CiEdit />
                      </div>
                      <div className="p-1 bg-red-500 border-2 rounded-md text-gray border-white">
                        <MdOutlinePersonRemove />
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#83CBFF] mt-2 px-2 rounded-md border-2 border-white">
                    <h2 className="text-base font-semibold">
                      {teammember.name}
                    </h2>
                  </div>
                  <p className="text-sm mt-2 text-gray-600">
                    {teammember.position}
                  </p>
                  <p className="text-sm mt-2 text-gray-500">
                    {teammember.about}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Modal for Add/Edit Team Member */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-[52] flex justify-center items-center">
            <div className="bg-transparent rounded-lg relative w-[450px] max-h-[90vh] overflow-y-auto no-scrollbar">
              <div className="bg-deeperpalegreen p-6 rounded-lg relative mx-1 my-2">
                <h2 className="text-lg font-bold text-gray-700 mb-2">
                  {isEditing ? "Edit Team Member" : "Add Team Member"}
                </h2>

                {/* Surname */}
                <input
                  type="text"
                  name="surname"
                  placeholder="Surname"
                  defaultValue={isEditing ? selectedMember?.surname : ""}
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />

                {/* FirstName */}
                <input
                  type="text"
                  name="firstname"
                  placeholder="Firstname"
                  defaultValue={isEditing ? selectedMember?.firstname : ""}
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />

                {/* Position */}
                <input
                  type="text"
                  name="position"
                  placeholder="Position"
                  defaultValue={isEditing ? selectedMember?.position : ""}
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 p-2 border text-left rounded-md"
                />

                {/* Mini Bio */}
                <ReactQuill
                  theme="bubble"
                  defaultValue={isEditing ? selectedMember?.about : ""}
                  placeholder="About - Less than 20 words"
                  style={{ border: "2px solid green", color: "black" }}
                  className="text-base block w-full mb-2 border text-left rounded-md"
                />

                {/* Image Upload */}
                <div>
                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer inline-flex items-center px-4 py-2 border-2 border-green-500 rounded-md bg-lightgreen text-black font-medium"
                  >
                    {selectedImage ? "Change Image" : "Upload Image"}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    name="image"
                    className="hidden"
                  />
                </div>

                {/* Modal Buttons */}
                <div className="flex justify-end items-center space-x-1 mt-2">
                  <p
                    className="text-red-600 cursor-pointer"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </p>
                  <button
                    type="submit"
                    className="bg-buttonColor text-white px-5 py-2 rounded-lg hover:bg-green-600"
                  >
                    {isEditing ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Search Modal */}
        {showSearchModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-[52] flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-2/3 sm:w-1/3 relative">
              <div
                className="flex justify-end text-red-500"
                onClick={closeSearchModal}
              >
                <AiOutlineCloseCircle size={20} />
              </div>
              <h2 className="text-lg font-bold mb-4">Search Team Member</h2>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchInputChange}
                placeholder="Enter Member Name"
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              <button
                onClick={confirmSearch}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Search
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ManageTeam;
