"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useRef } from "react"; // Import useRef
import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";

const images = [
  {
    id: "1",
    src: "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Homepage%20Gallery%20Images%2FSEEDAF-boys-2.jpeg?alt=media&token=fb9ee595-6d24-4ba9-a0d1-f519c156ae3c",
    alt: "seedafrique staff in reflectors",
    blurhash: "L8I|9z?b00D%IqxZtSog0fn#={t7",
  },

  {
    id: "2",
    src: "/homepagegalleryimages/billydega.jpeg",
    alt: "billy dega",
    blurhash:
      "[T5ap~fQeogfd8e:e:b]cqf*e.fkgfg2f+e.buf*f*eofkfkf*eofkfkf6f6f*fke:f6f6e:f6fk",
  },

  {
    id: "3",
    src: "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Homepage%20Gallery%20Images%2FStadiumView.jpg?alt=media&token=ae815e46-ab8e-4777-b509-1df141dfe9e9",
    alt: "Stadium View",
    blurhash: "L8DttR^*00008D%M?ZMx_3?^IVbH",
  },

  {
    id: "4",
    src: "/homepagegalleryimages/candpr.jpg",
    alt: "co founder and pro of seed afrique",
    blurhash:
      "[T5ap~fQeogfd8e:e:b]cqf*e.fkgfg2f+e.buf*f*eofkfkf*eofkfkf6f6f*fke:f6f6e:f6fk",
  },

  {
    id: "5",
    src: "/homepagegalleryimages/esther_dzata.jpg",
    alt: "esther dzata, young engineer",
    blurhash: "CT5ap~fQeogfd8e:e:b]",
  },

  {
    id: "6",
    src: "/homepagegalleryimages/mwg.jpg",
    alt: "seedafrique and mobile web ghana",
    blurhash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
  },

  {
    id: "7",
    src: "/homepagegalleryimages/cyclers.jpeg",
    alt: "seedafrique at cycling event",
    blurhash: "=T5ap~d8cqgfbufkfkf*f6",
  },
  {
    id: "8",
    src: "/homepagegalleryimages/man-in-blue.jpg",
    alt: "man in blue",
    blurhash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
  },
  {
    id: "9",
    src: "/homepagegalleryimages/nath-with-takyisky.jpg",
    alt: "nath with takyisky",
    blurhash: "=65baSd5c-hlifj@i3k-iM",
  },
];

const images2 = [
  {
    id: "1",
    src: "/homepagegalleryimages/allyelllowgrouppgoto.jpeg",
    alt: "seed afrique at Osagyefo Criterium",
    blurhash:
      "|D5baSh,d5c-ifgKb@i|e:cUcUiMizfQf7e:g1f*cUfjg1f7f*f7epepe:i3e:eDe:gKf*fjg1f*eWf7f*g1f*e;fjfjf7j@f*gKe;eWe:fje:f7eDg1e:epe:f*fjf*f*kUepg1f*f%f%fje;e;eWfjepf%f*e:fjf*f7",
  },

  {
    id: "2",
    src: "/homepagegalleryimages/ewurakuachristineobubar.jpg",
    alt: "Ewurakua Christine Obubar",
    blurhash: "L8I|9z?b00D%IqxZtSog0fn#={t7",
  },

  {
    id: "3",
    src: "/homepagegalleryimages/groupphoto1.jpeg",
    alt: "seed afrique group photo",
    blurhash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
  },

  {
    id: "4",
    src: "/homepagegalleryimages/edwinsarpong.jpeg",
    alt: "Edwin Sarpong Sarkwaa",
    blurhash: "L8DttR^*00008D%M?ZMx_3?^IVbH",
  },

  {
    id: "5",
    src: "/homepagegalleryimages/pasino.jpg",
    alt: "Seed afrique at Ghana Code Club",
    blurhash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
  },

  {
    id: "6",
    src: "/homepagegalleryimages/mike_vex.jpeg",
    alt: "Mike Dzata with vex robot -- Seed afrique at Ghana Code Club",
    blurhash: "L8I|9z?b00D%IqxZtSog0fn#={t7",
  },

  {
    id: "7",
    src: "/homepagegalleryimages/nat.jpg",
    alt: "Nathaniel Bobbie",
    blurhash: "L8DttR^*00008D%M?ZMx_3?^IVbH",
  },

  {
    id: "8",
    src: "/homepagegalleryimages/grc_22.jpg",
    alt: "Seed afrique at Ghana Robotics Competition 2022",
    blurhash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
  },
  {
    id: "9",
    src: "/homepagegalleryimages/SEEDAF-boys-2.jpeg",
    blurhash: "L8I|9z?b00D%IqxZtSog0fn#={t7",
  },
  {
    id: "10",
    src: "/homepagegalleryimages/StadiumView.jpg",
    blurhash: "L5H2EC=PM+yV0g-mq.wG9c010J}I",
  },
];

export default function GalleryOnHome() {
  return (
    <>
      <HomePageGalleryTop></HomePageGalleryTop>
      <HomePageGalleryBottom></HomePageGalleryBottom>
    </>
  );
}

const HomePageGalleryTop = () => {
  const swiperRef = useRef(null); // Create a ref for the Swiper instance

  // Function to handle image click
  // const handleImageClick = () => {
  //   if (swiperRef.current) {
  //     swiperRef.current.autoplay.stop(); // Stop autoplay
  //     setTimeout(() => {
  //       swiperRef.current.autoplay.start(); // Restart autoplay after 5 seconds
  //     }, 5000);
  //   }
  // };

  // State for tracking the modal visibility and selected image
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState({});

  // Disable scroll when modal is open
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Enable scroll
    }

    return () => {
      document.body.style.overflow = ""; // Reset on cleanup
    };
  }, [modalOpen]);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
    swiperRef.current?.autoplay.stop();
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
    swiperRef.current?.autoplay.start();
  };

  // Insead of using the use stae as a single vallue when you are dealing with a lot use it as variables

  // For the blur hash
  const handleImageLoad = (id) => {
    setImageLoaded((prev) => ({ ...prev, [id]: true })); // Set the specific image as loaded
  };

  return (
    <>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Set the Swiper instance to the ref
        slidesPerView={3}
        spaceBetween={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 1,
          },
        }}
        modules={[Autoplay]}
        autoplay={{
          delay: 1000,
        }}
        className="mySwiper mt-3"
      >
        {images.map((image) => (
          <SwiperSlide key={image.id} className="mx-2 lg:mx-5">
            <div className="relative">
              {!imageLoaded[image.id] && (
                <div className="h-40 sm:h-56 rounded-xl overflow-hidden absolute inset-0">
                  {/* This div is to control the blur hash so that it perfectly fits the size of the sizing of the image */}
                  <Blurhash
                    hash={image.blurhash}
                    height={"100%"}
                    width={"100%"}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                </div>
              )}

              <img
                src={image.src}
                alt={image.alt}
                className={`rounded-xl object-cover h-40 sm:h-56 lg:w-80 w-full transition-opacity duration-500 ease-in-out ${
                  imageLoaded[image.id] ? "opacity-100" : "opacity-0"
                }`}
                onClick={() => openModal(image)} // Add click event to stop autoplay
                onLoad={() => handleImageLoad(image.id)} // when it is done loading this one would work
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Modal that opens when you slick on the image at the top gallery  */}
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 ">
          <div className="relative">
            <img
              src={selectedImage.src}
              alt={selectedImage.alt}
              className=" max-h-screen max-w-screen "
            />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 bg-white text-black p-2 rounded-full"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const HomePageGalleryBottom = () => {
  const swiperRef = useRef(null); // Create a ref for the Swiper instance

  // State for tracking the modal visibility and selected image
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState({});

  // Disable scroll when modal is open
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Enable scroll
    }

    return () => {
      document.body.style.overflow = ""; // Reset on cleanup
    };
  }, [modalOpen]);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
    swiperRef.current?.autoplay.stop();
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
    swiperRef.current?.autoplay.start();
  };

  const handleImageLoad = (id) => {
    setImageLoaded((prev) => ({ ...prev, [id]: true }));
  };

  return (
    <>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)} // Set the Swiper instance to the ref
        slidesPerView={3}
        spaceBetween={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 1,
          },
        }}
        modules={[Autoplay]}
        autoplay={{
          delay: 1000,
        }}
        className="mySwiper mt-3"
      >
        {images2.map((image) => (
          <SwiperSlide key={image.id} className="mx-2 lg:mx-5">
            {!imageLoaded[image.id] && (
              <div className="h-40 sm:h-56 rounded-xl overflow-hidden absolute inset-0">
                {/* This div is to control the blur hash so that it perfectly fits the size of the sizing of the image */}
                <Blurhash
                  hash={image.blurhash}
                  height={"100%"}
                  width={"100%"}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              </div>
            )}
            <img
              src={image.src}
              alt={image.alt}
              className={`rounded-xl object-cover h-40 sm:h-56 lg:w-80  w-full transition-opacity duration-500 ease-in-out ${
                imageLoaded[image.id] ? "opacity-100" : "opacity-0"
              }`}
              onClick={() => openModal(image)} // Add click event to stop autoplay
              onLoad={() => handleImageLoad(image.id)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Modal that opens when you slick on the image at the top gallery  */}
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80 ">
          <div className="relative">
            <img
              src={selectedImage.src}
              alt={selectedImage.alt}
              className=" max-h-screen max-w-screen "
            />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 bg-white text-black p-2 rounded-full"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </>
  );
};
