import React from "react";
import { Link } from "react-router-dom";
import DashboardBackground from "../../assets/admindashboard/DashboardBackground.jpeg";

const AdminDashboard = () => {
  return (
    //MAIN CONTAINER FOR THE OBJECTS ON THE PAGE

    // style={{ backgroundImage: `url(${DashboardBackground})` }}

    <div>
      <div className="sm:block hidden">
        <div className="admin-dashboard h-svh flex m-10 ">
          {/* //DIV FOR ITEMS ON THE LEFT SIDE */}
          <div className="main-Left-Side-Container w-8/12  flex flex-col space-y-10">
            {/* THE CONTAINER FOR THE DASGBOARD IMAGE AND ALL THE ITEMS INSIDE */}
            <div className="Greeting-and-management-links-Container  bg-cover bg-center w-full h-4/6  bg-[url('./assets/admindashboard/DashboardBackground.jpeg')] rounded-3xl flex justify-center items-center flex-col space-y-6">
              <p className="text-[#CEDAB9] font-Poppins font-medium text-4xl text-center ">
                Hello <span className="text-black bg-[#CEDAB9]">Takyi</span>{" "}
              </p>
              <div className="text-4xl font-Poppins font-medium text-[#E4DFD6] leading-snug">
                <p className="text-center">Welcome to the admin Dashboard</p>
                <p className="text-center">
                  What will you like to work on today?
                </p>
              </div>
              {/* THE DIV HOLDING ALL THE BUTTONS */}
              <div className="flex gap-3 font-Poppins">
                <Link
                  to="/admin/admindashboard-content/manage-blogs"
                  className="text-xl"
                >
                  <button className="bg-loginButtonColour px-2 py-1 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110">
                    BLOG
                  </button>
                </Link>
                <Link
                  to="/admin/admindashboard-content/manage-gallery"
                  className="text-xl "
                >
                  <button className=" bg-loginButtonColour  py-1 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110">
                    GALLERY
                  </button>
                </Link>
                <Link
                  to="/admin/admindashboard-content/manage-events"
                  className="text-xl"
                >
                  <button className=" bg-loginButtonColour px-2 py-1 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110">
                    EVENT
                  </button>
                </Link>
                <Link
                  to="/admin/admindashboard-content/manage-requests"
                  className="text-xl"
                >
                  <button className=" bg-loginButtonColour px-2 py-1 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110">
                    REQUESTS
                  </button>
                </Link>
                <Link
                  to="/admin/admindashboard-content/manage-team"
                  className="text-xl"
                >
                  <button className=" bg-loginButtonColour px-2 py-1 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110">
                    Manage Team
                  </button>
                </Link>
                <button className=" bg-red-600 px-2 py-1 rounded-lg">
                  YT LINK
                </button>
              </div>
            </div>
            {/* CONTAINER FOR THE ITEMS BELOW THE DASHBOARD IMAGE */}
            <div className="metrics-counters-on-the-left-side w-full  flex justify-between size-40 text-center items-center">
              <div className="container1 w-3/12 h-full bg-dashboardContainerColour rounded-xl  p-5">
                <p className="font-Poppins font-bold text-4xl">20</p>
                <p className="font-Poppins font-semibold text-2xl">
                  Total Blogs
                </p>
              </div>

              <div className="container2 w-3/12 h-full bg-dashboardContainerColour rounded-xl p-5">
                <p className="font-Poppins font-bold text-4xl">15</p>
                <p className="font-Poppins font-semibold text-2xl">
                  Total Images
                </p>
              </div>

              <div className="container3 w-4/12 h-full bg-dashboardContainerColour rounded-xl p-5">
                <p className="font-Poppins font-bold text-4xl">7</p>
                <p className="font-Poppins font-semibold text-2xl">
                  Upcoming Events
                </p>
              </div>
            </div>
          </div>

          {/* DIV FOR ITEMS ON THE RIGHT SIDE */}
          <div className=" w-4/12 flex flex-col items-center space-y-8 ">
            <div className="top-container bg-dashboardContainerColour h-2/6 w-4/6  rounded-xl  flex items-center justify-center px-10 font-Poppins">
              <div className="space-y-5 w-3/6 rounded-xl text-center">
                <p className="font-medium text-sm">Web visitors</p>
                <div className="bg-inherit border-4 border-black p-5 text-center rounded-xl">
                  <p>85</p>
                </div>
              </div>
            </div>

            <div className="middle-container h-4/6 w-4/6 bg-dashboardContainerColour flex items-center justify-center rounded-xl ">
              <div className="text-center  font-Poppins space-y-4">
                <p className="text-xl">
                  New <br /> Aplications{" "}
                </p>
                <div className="bg-inherit border-4 border-black p-5 text-center rounded-xl">
                  <p className="text-2xl">10</p>
                </div>

                <div className="">
                  <button className=" bg-loginButtonColour text-xl font-Poppins px-2 py-1 rounded-lg transition-transform ease-in-out duration-300 transform hover:scale-110">
                    Check
                  </button>
                </div>
              </div>
            </div>

            <div className="bottom-container h-1/6 flex items-center  space-x-6">
              <div>
                <button className="bg-loginButtonColour w-32 py-3 font-Poppins font-medium px-2  rounded-lg transition-transform ease-in-out duration-300 transform hover:scale-110">
                  ADD ADMIN
                </button>
              </div>
              <div className="bg-[#D24B36] rounded-xl p-2 transition-transform ease-in-out duration-300 transform hover:scale-110">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  className=""
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="white"
                    d="M1.019 8a6.462 6.462 0 0 0 1.003 3h2.382a14.469 14.469 0 0 1-.396-3zm0-1h2.989c.033-1.078.172-2.094.396-3H2.022a6.462 6.462 0 0 0-1.003 3M13.98 8h-2.989a14.469 14.469 0 0 1-.396 3h2.382a6.462 6.462 0 0 0 1.003-3m0-1a6.462 6.462 0 0 0-1.003-3h-2.382c.224.906.363 1.922.396 3zM5.008 8c.037 1.107.195 2.127.429 3h4.126c.234-.873.392-1.893.429-3zm0-1h4.984a13.422 13.422 0 0 0-.429-3H5.437a13.422 13.422 0 0 0-.429 3M.016 8H0V7h.016a7.5 7.5 0 0 1 14.968 0H15v1h-.016A7.5 7.5 0 0 1 .016 8m2.794 4a6.501 6.501 0 0 0 2.717 1.695A7.315 7.315 0 0 1 4.7 12zm9.38 0H10.3c-.23.657-.51 1.23-.827 1.695A6.501 6.501 0 0 0 12.19 12m-6.428 0c.484 1.24 1.132 2 1.738 2 .606 0 1.254-.76 1.738-2zM2.81 3H4.7c.23-.657.51-1.23.827-1.695A6.501 6.501 0 0 0 2.81 3m9.38 0a6.501 6.501 0 0 0-2.717-1.695c.317.465.597 1.038.827 1.695zM5.762 3h3.476C8.754 1.76 8.106 1 7.5 1c-.606 0-1.254.76-1.738 2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="visible sm:hidden">
        <div className="relative">
          <div>
            <img
              className="fixed h-screen object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462e"
            />
          </div>

          {/* Links */}
          <div className="inset-0 flex flex-col items-center justify-center fixed h-screen space-y-5">
            <Link to="/admin/admindashboard-content/manage-blogs">
              <p className="bg-buttonColor px-4 py-3 rounded-md">Blog</p>
            </Link>
            <Link to="/admin/admindashboard-content/manage-gallery">
              <p className="bg-buttonColor px-4 py-3 rounded-md ">Gallery</p>
            </Link>{" "}
            <Link to="/admin/admindashboard-content/manage-events">
              <p className="bg-buttonColor px-4 py-3 rounded-md ">Event</p>
            </Link>
            <Link to="/admin/admindashboard-content/manage-requests">
              <p className="bg-buttonColor px-4 py-3 rounded-md ">
                Manage Requests
              </p>
            </Link>
            <Link to="/admin/admindashboard-content/manage-team">
              <p className="bg-buttonColor px-4 py-3 rounded-md ">
                Manage Team
              </p>
            </Link>
            {/* <Link to="">
              <p className="bg-buttonColor px-4 py-3 rounded-md ">Link</p>
            </Link> */}
            <Link to="/home">
              <p className="bg-buttonColor px-4 py-3 rounded-md ">Web</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
