// About SEEDAfrique , Core Values , the Mission and  Vission

import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";
import { motion, useScroll, useTransform } from "framer-motion";

//  Main
const SeedAfriqueOrganization = () => {
  return (
    <div>
      {/* Seed Afrique Organization text with some text on the left and the mission on the right side  */}
      <SeedAfriqueAbout />
    </div>
  );
};

export default SeedAfriqueOrganization;

// About SeedAfrique - All Screens
function SeedAfriqueAbout() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-mission-and-vission.png?alt=media&token=0c5e6050-c7d9-4d4c-99ed-a0ee006be3e0";

    // When the image is fully loaded, set imageLoaded to true
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-4 sm:p-8 mt-3 sm:mt-10">
      {/* left side  */}
      <motion.div className="md:w-1/2 md:pr-8 mb-8 md:mb-0 text-center md:text-left">
        <h1 className="font-poppins text-lg font-bold sm:text-4xl sm:font-extrabold mb-2 sm:mb-6 text-teal-950">
          SEEDAfrique Organization
        </h1>
        <p className="font-Poppins text-sm sm:text-lg text-darkGreen leading-5 sm:leading-8">
          The Organization develops and harnesses the young minds of the growing
          Youth in Ghana and Africa. Our aim is to stimulate the interest of the
          Youth in Engineering and other STEM-related fields by connecting them
          to industries of their specialization to further build and hone their
          skills and talents. Membership is open to young people from the Junior
          through Senior High School level (K-12) to the graduate/early career
          level
        </p>
      </motion.div>

      {/* right side  */}
      {/* The image with Takyisky on it */}
      <motion.div className="md:w-1/2 w-full rounded-xl text-paleGreen sm:p-5 relative">
        {imageLoaded ? (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2Fblurry-mission-and-vission.png?alt=media&token=0c5e6050-c7d9-4d4c-99ed-a0ee006be3e0"
            className="object-cover rounded-xl w-full h-[400px] md:h-[700px] lg:h-[600px] "
          />
        ) : (
          <div className="object-cover overflow-hidden rounded-xl w-full h-[400px] md:h-[700px] lg:h-[600px]">
            <Blurhash
              hash="]56+hI8_*J.8MxPpx^MdVsn$tmShxYaxbbH=niROWVkqNGn$bcbvs.n$bbtRe.V?R5t8R+V@WBo#Rjs:kCoJM|o0aKkBbc"
              width={"100%"}
              height={"100%"} // Adjust height to match the height of the actual image
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}

        <div className="absolute inset-0 p-4 sm:p-8 space-y-4 flex flex-col justify-center items-center ">
          <p className="font-Poppins text-sm sm:text-lg sm:my-2">
            {" "}
            <span className="font-bold text-white"> Our Mission</span> is to
            lead the African youth through STEM Education for viable
            opportunities.
          </p>
          <p className="font-Poppins text-sm sm:text-lg my-2">
            {" "}
            <span className="font-bold text-white ">
              {" "}
              Strategic Engineering Experience DriveAfrique (SEEDAfrique){" "}
            </span>{" "}
            is an organisation that connects individuals with STEM Interest for
            growth.
          </p>
          <p className="font-Poppins text-sm sm:text-lg my-2">
            {" "}
            <span className="font-bold text-white "> Our Vision </span> is to
            create a pool of dynamic experts for social and economic growth.{" "}
          </p>
          <p className="font-Poppins text-sm sm:text-lg my-2">
            {" "}
            Our sole purpose is to engage students in{" "}
            <span className="font-bold text-white"> STEM </span> activities to
            develop their skills in problem-solving, creativity, and critical
            analysis to achieve their potential for transformation.{" "}
          </p>
        </div>
      </motion.div>
    </div>
  );
}



