"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";
import axios from "axios"; // Axios for API calls
import { Pagination, Navigation } from "swiper/modules";

// Main Component for Upcoming Events
export default function UpcomingEventsOnHome() {
  return (
    <>
      <h1 className="text-lg sm:text-left text-center sm:text-3xl text-emerald-900 font-bold sm:font-semibold antialiased font-Poppins mb-1 mt-10 sm:ml-6">
        Upcoming Events
      </h1>
      <h2 className="text-sm font-Poppins sm:text-left text-center sm:text-xl text-emerald-900 font-normal antialiased mb-5 sm:ml-6">
        Stay Updated to know what's next.
      </h2>

      <div className="px-4 mb-11">
        <UpcomingEventsOnHomeSwiper />
      </div>
    </>
  );
}

// Modal Component
// Modal Component
const Modal = ({ show, onClose, content }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open"); // Disable background scroll
    } else {
      document.body.classList.remove("modal-open"); // Enable background scroll
    }

    return () => {
      document.body.classList.remove("modal-open"); // Clean up when the modal is closed
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[52] flex justify-center items-center">
      <div className="bg-transparent rounded-lg relative w-[450px] max-h-[90vh] overflow-y-auto no-scrollbar">
        <div className="bg-deeperpalegreen p-6 rounded-lg relative mx-1 my-2 ">
          <h2 className="text-lg font-bold mb-4">Event Details</h2>
          <div
            className="full-description"
            dangerouslySetInnerHTML={{ __html: content }} // Use content prop
          ></div>
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

// Swiper Component with API Fetch
const UpcomingEventsOnHomeSwiper = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [imageLoaded, setImageLoaded] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [isToday, setIsToday] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [dateDetails, setDatedetails] = useState([]);

  // Function to handle image load
  const handleImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  const openModal = (fullDescription) => {
    setModalContent(fullDescription);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  // Fetch the events from the API
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/events"
        ); // Replace with your actual API endpoint

        console.log("API Response:", response.data); // Log the API response for debugging

        // Get the current date (only the date part, without time)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of the day (00:00:00)

        // Filter and sort the events by date
        const filteredAndSortedEvents = response.data
          .filter((event) => {
            const eventDate = new Date(event.eventDate);
            console.log("Event Date:", eventDate); // Log each event date for debugging
            return eventDate >= today;
          })
          .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));

        console.log("Filtered and Sorted Events:", filteredAndSortedEvents); // Log the filtered and sorted events

        // Extract and format the date details
        const formattedEvents = filteredAndSortedEvents.map((event) => {
          const eventDate = new Date(event.eventDate);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          };
          const formattedDate = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(eventDate);

          const [day, month, dayNumber, year] = formattedDate
            .replace(/,/g, "")
            .split(" ");

          return {
            ...event,
            day, // "Mon"
            dayNumber, // "10"
            month, // "Aug"
            year, // "2024"
          };
        });

        console.log("Formated Events:", formattedEvents);

        setUpcomingEvents(formattedEvents);

        setLoading(false);
      } catch (err) {
        console.error("API Error:", err); // Log the error if the API call fails
        setError("Failed to load events. Please try again later.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-28 sm:h-36">
        {/* Loading Spinner */}
        <svg
          className="animate-spin h-10 w-10 text-green-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <p className="text- sm:text-xl text-gray-600 ml-3">Loading events...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-red-500 text-xl">
          {error || "There was an issue fetching events."}
        </p>
      </div>
    );
  }

  if (!upcomingEvents.length) {
    return (
      <div className="flex justify-center items-center h-28 sm:h-36 flex-col">
        <svg
          className="h-16 w-16 text-aboutbg mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 8c-1.1046 0-2 .8954-2 2s.8954 2 2 2 2-.8954 2-2-.8954-2-2-2zm0 8a5.979 5.979 0 01-4.244-1.757A5.979 5.979 0 016 12H4a7.979 7.979 0 002.343 5.657A7.979 7.979 0 0012 20a7.979 7.979 0 005.657-2.343A7.979 7.979 0 0020 12h-2a5.979 5.979 0 01-1.757 4.243A5.979 5.979 0 0112 16z"
          />
        </svg>
        <p className="text-base sm:text-xl text-gray-600 text-center bg-red-300 px-3 py-1 sm:px-5 sm:py-3 rounded-xl">
          No upcoming events available at the moment.
        </p>
      </div>
    );
  }

  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        navigation={{ clickable: true }}
        breakpoints={{
          550: { slidesPerView: 1 },
          580: { slidesPerView: 2 },
          860: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
          1440: { slidesPerView: 6 },
        }}
        pagination={{ dynamicBullets: true }}
        modules={[Pagination, Navigation]}
      >
        {upcomingEvents.map((item, index) => {
          const eventDate = new Date(item.eventDate);
          eventDate.setHours(0, 0, 0, 0); // Ignore time

          // Get today's date
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          // Check if this event is happening today
          const isToday = eventDate.getTime() === today.getTime();

          const charLimit = 60;

          const stripHtmlTags = (html) => {
            const tmp = document.createElement("div");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
          };

          const truncatedDescription = stripHtmlTags(item.description).slice(
            0,
            charLimit
          );

          return (
            <SwiperSlide key={index}>
              <div className="rounded-2xl ml-1 mb-1 mr-1 flex flex-col min-h-[530px] xs:min-h-[500px] justify-between ">
                {/* Blurhash loading (used before the image loads) */}
                <div>
                  <div className="w-full md:h-52 lg:h-48 h-72 object-cover mb-4 rounded-t-2xl relative">
                    {!imageLoaded[index] && (
                      <div className="w-full  md:h-52 lg:h-48 h-72 inset-0 rounded-t-2xl overflow-hidden">
                        <Blurhash
                          hash={
                            item.blurHash ||
                            "VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                          } // Default Blurhash
                          width={"100%"}
                          height={"100%"} // Adjust height to match the height of the actual image
                          resolutionX={32}
                          resolutionY={32}
                          punch={1}
                        />
                      </div>
                    )}
                    <div>
                    
                      <img
                        src={
                          item.imageUrl ||
                          "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462"
                        } // Fallback image
                        alt={item.title || "Event Image"}
                        className={`w-full md:h-52 lg:h-48 h-72 object-cover mb-4 rounded-t-2xl ${
                          imageLoaded[index] ? "" : "hidden"
                        }`}
                        onLoad={() => handleImageLoad(index)}
                      />
                    </div>

                    {/* Date section */}
                    <div className="flex justify-end absolute inset-0">
                      <div
                        className={`rounded-xl border-4 h-20 p-2 mr-1 mt-1 flex flex-col justify-between ${
                          isToday
                            ? "border-blue-500 bg-red-500"
                            : "border-green-950 bg-paleGreen"
                        }`}
                      >
                        <center>
                          <p
                            className={`text-xs font-Poppins font-bold ${
                              isToday ? "text-white" : "text-green-950"
                            }`}
                          >
                            {item.day || "Unknown Day"}
                          </p>
                        </center>
                        <center>
                          <p
                            className={`text-xs font-Poppins font-bold ${
                              isToday ? "text-white" : "text-green-950"
                            }`}
                          >
                            {item.dayNumber || "--"}
                          </p>
                        </center>
                        <center>
                          <p
                            className={`text-xs font-Poppins font-bold ${
                              isToday ? "text-white" : "text-green-950"
                            }`}
                          >
                            {item.month + " " + item.year || "Unknown Date"}
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* Event Category */}
                    <h2 className="rounded-md w-2/4 lg:w-3/4 text-sm bg-green-800 text-lime-50 text-center mt-2 py-2 px-2  font-Poppins">
                      {item.category || "Uncategorized"}
                    </h2>

                    {/* Event Title */}
                    <h1 className="text-lg font-semibold text-green-950 mt-2 font-Poppins">
                      {item.title || "Untitled Event"}
                    </h1>
                  </div>
                </div>

                {/* Event Description */}
                <p className="text-emerald-900 text-sm font-Poppins">
                  {truncatedDescription}
                  {item.description.length > charLimit && (
                    <button
                      className="text-blue-500 underline ml-2"
                      onClick={() => openModal(item.description)}
                    >
                      More
                    </button>
                  )}
                </p>

                <div className="flex-col ">
                  {/* Main Content */}
                  <div className="flex flex-col justify-between">
                    {/* Event Venue */}
                    <div className="flex justify-between">
                      <a
                        className="text-emerald-900 mb-2 underline"
                        href={item.googleMapLink || "#"} // Dynamically set the venue link or a fallback "#"
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Prevents security risks with external links
                      >
                        {item.venue || "No venue specified"}
                      </a>
                      <a
                        className="text-blue-600 mb-2 underline"
                        href={item.registrationLink || "/joinus"} // Dynamically set the registration link or fallback "#"
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Prevents security risks with external links
                      >
                        Register
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* Modal for showing full description */}
      <Modal show={isModalOpen} onClose={closeModal} content={modalContent} />
    </>
  );
};

const Skeleton = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {/* Card 1 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 2 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 3 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 4 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 1 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 2 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 3 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 4 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 1 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 2 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 3 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 4 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>
      {/* Card 1 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 2 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 3 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 4 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>
      {/* Card 1 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 2 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 3 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>

      {/* Card 4 */}
      <div className="animate-pulse space-y-4  p-2 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded"></div>
            <div className="w-28 h-3 bg-gray-300 rounded"></div>
          </div>
          <div className="w-11 h-11 bg-gray-300 rounded-md"></div>
        </div>
        <div className="w-full h-72 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
};
