import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Blurhash } from "react-blurhash";

function UpcomingEvents() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [imageLoaded, setImageLoaded] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const eventRefs = useRef([]); // To store references to each event card

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/events"
        );
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const filteredAndSortedEvents = response.data
          .filter((event) => new Date(event.eventDate) >= today)
          .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
          .map((event) => {
            const eventDate = new Date(event.eventDate);
            const options = {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            };
            const formattedDate = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(eventDate);
            const [day, month, dayNumber, year] = formattedDate
              .replace(/,/g, "")
              .split(" ");
            return { ...event, day, dayNumber, month, year };
          });

        setUpcomingEvents(filteredAndSortedEvents);
        setLoading(false);
      } catch (err) {
        console.error("API Error:", err);
        setError("Failed to load events. Please try again later.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = entry.target.getAttribute("data-index");
            setImageLoaded((prev) => ({ ...prev, [index]: true }));
          }
        });
      },
      {
        rootMargin: "0px 0px 200px 0px", // Adjust this threshold for early loading
        threshold: 0.1,
      }
    );

    eventRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      eventRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [upcomingEvents]);

  const toggleText = (index) =>
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));

  const charLimit = 40;

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mx-2 sm:mx-5 my-2 text-center">
      <h1 className="text-lg sm:text-3xl text-emerald-900 font-bold sm:font-semibold antialiased font-Poppins mb-1">
        Upcoming Events
      </h1>
      <h2 className="text-sm sm:text-xl text-emerald-900 font-normal antialiased mb-5">
        Stay Updated to know what's next.
      </h2>

      <div className="flex flex-col items-center space-y-10">
        {upcomingEvents.map((item, index) => {
          const isExpanded = expandedIndex === index;
          const sanitizedDescription = DOMPurify.sanitize(item.description);
          const plainDescription = stripHtmlTags(sanitizedDescription);
          const truncatedDescription = plainDescription.slice(0, charLimit);

          return (
            <div
              key={index}
              className="w-full max-w-md mx-auto p-2 rounded-2xl shadow-lg flex flex-col items-center bg-deeperpalegreen"
              data-index={index} // Used to identify each card for Intersection Observer
              ref={(el) => (eventRefs.current[index] = el)} // Store ref for each event card
            >
              <div className="w-full h-64 relative rounded-2xl overflow-hidden mb-4">
                {!imageLoaded[index] && (
                  <div className="w-full h-64 inset-0 rounded-2xl overflow-hidden">
                    <Blurhash
                      hash={
                        item.blurHash ||
                        "VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                      }
                      width="100%"
                      height="100%"
                      resolutionX={32}
                      resolutionY={32}
                      punch={1}
                    />
                  </div>
                )}
                {imageLoaded[index] && (
                  <img
                    src={
                      item.imageUrl ||
                      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462"
                    }
                    alt={item.title || "Event Image"}
                    className="w-full h-64 object-cover rounded-2xl"
                  />
                )}
                <div className="absolute top-2 right-2 bg-green-800 text-lime-50 text-xs font-bold rounded-md p-2">
                  <p>{item.day}</p>
                  <p>{item.dayNumber}</p>
                  <p>{`${item.month} ${item.year}`}</p>
                </div>
              </div>

              <h2 className="text-sm bg-green-800 text-lime-50 text-center mt-2 py-1 px-3 rounded-full font-Poppins">
                {item.category || "Uncategorized"}
              </h2>
              <h1 className="text-lg text-justify font-semibold text-green-950 mt-2 font-Poppins">
                {item.title || "Untitled Event"}
              </h1>

              <p className="text-emerald-900 text-justify text-sm font-Poppins mt-3 ">
                {isExpanded ? (
                  <span
                    className="mr-2"
                    dangerouslySetInnerHTML={{
                      __html: sanitizedDescription,
                    }}
                  ></span>
                ) : (
                  <span className="mr-2">{truncatedDescription}...</span>
                )}
                {item.description.length > charLimit && (
                  <button
                    className="text-blue-500 underline"
                    onClick={() => toggleText(index)}
                  >
                    {isExpanded ? "Show Less" : "More"}
                  </button>
                )}
              </p>

              <div className="flex items-center my-4 space-x-7">
                <a
                  className="text-emerald-900 underline"
                  href={item.googleMapLink || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.venue || "No venue specified"}
                </a>
                <a
                  className="text-blue-600 underline"
                  href={item.registrationLink || "/joinus"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UpcomingEvents;

const Skeleton = () => {
  return (
    <div className="animate-pulse space-y-4 w-full max-w-md mx-auto p-2 mt-2 rounded-2xl">
      {/* Skeleton loader */}
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="w-44 h-5 bg-gray-300 rounded mx-auto"></div>
        <div className="w-44 h-5 bg-gray-300 rounded mx-auto"></div>
      </div>
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>{" "}
      <div className="w-full h-72 bg-gray-300 rounded-xl"></div>
    </div>
  );
};
