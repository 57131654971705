import React from "react";

const ManageBlogs = () => {
  return (
    <div className="manage-events ml-7 mt-6">
      {/* Top section */}
      <div className="flex items-center">
        <span className="bg-buttonColor text-sm px-5 py-2 h-9 rounded-lg hover:bg-green-600">
          {" "}
          ADD BLOG{" "}
        </span>
        {/* Button Image */}
        <div>
          {" "}
          <img
            src="/adminsidebarimages/basil_add-outline.png"
            className="h-11"
          />{" "}
        </div>
      </div>

      <div>
        <ManageBlogsTemp />
        {/* Comntent would go here  */}
      </div>
    </div>
  );
};

export default ManageBlogs;

const ManageBlogsTemp = () => {
  return <div>Under Development ....</div>;
};
