// All image imports
import blurCVAGB from "../images/aboutpgimgs/FASUGamesLagos.jpg";
import eDzata from "../images/aboutpgimgs/Dzata Emma.jpg";

import eObodai from "../images/aboutpgimgs/EmmanuelObodai.jpg";

const OurTeamSwiperData = [
  {
    image: blurCVAGB,
    firstname: "Nathaniel",
    surname: "Newton",
    name: "Nathaniel Newton",
    position: "Senior Software Engineer",
    about: "Nathaniel leads complex software projects, bringing over ten years of expertise and innovation to each solution he crafts.",
  },
  {
    image: eDzata,
    firstname: "Emmanuel",
    surname: "Dzata",
    name: "Emmanuel Dzata",
    position: "Photographer",
    about: "Emmanuel captures and edits breathtaking photos, merging technical skill with a keen artistic eye.",
  },
  {
    image: eObodai,
    firstName: "Emmanuel",
    surname: "Dalton",
    name: "Emmanuel Dalton",
    position: "CEO",
    about: "As CEO, Emmanuel builds scalable, high-performance software solutions, leading with a focus on efficiency and innovation.",
  },
  {
    image: blurCVAGB,
    firstname: "Samuel",
    surname: "Damian",
    name: "Samuel Damian",
    position: "Project Manager",
    about: "Samuel excels in driving team success and ensuring project milestones are met through strategic leadership and collaboration.",
  },
  {
    image: eDzata,
    firstname: "Richard",
    surname: "Scott",
    name: "Richard Scott",
    position: "Chief Technology Officer",
    about: "Richard combines his tech expertise with a passion for innovation, overseeing impactful projects from inception to completion.",
  },
  {
    image: eObodai,
    firstname: "Emmanuel",
    surname: "Newton",
    name: "Emmanuel Newton",
    position: "Lead Developer",
    about: "Emmanuel is a seasoned developer who builds high-quality, functional software tailored to meet complex business needs.",
  },
];

export default OurTeamSwiperData;

