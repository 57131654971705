// This part contain the text you would find Thh the top of the hero section and beneath the nav bar
"use client";
import React from "react";

const headings = {
  text1: "Connecting Individuals With",
  text2: "STEM Interest For Growth",
  text3: "We Develop Leadership Potentials Through",
  text4: "STEM Education And Career Paths",
};

export default function TopmostHeroText() {
  return (
    <>
      {/* Key . So the "sm:" is for the tablet sized devices to the large till before the lage sized devices  */}
      {/* The "lg:" created the styling for the ones after the large sized devices  */}
      <div className="md:mx-7 md:my-14 my-5 hidden sm:block ">
        <h1 className="font-extrabold text-teal-950 font-Poppins text-center sm:text-2xl lg:text-5xl lg:mb-4">
          {" "}
          {headings.text1}{" "}
        </h1>
        <h1 className="font-extrabold text-teal-950 font-Poppins text-center  sm:text-2xl lg:text-5xl md:mb-1 lg:mb-4">
          {headings.text2}{" "}
        </h1>
        <h2 className="md:font-semibold font-Poppins text-darkGreen text-center  sm:text-lg lg:text-2xl lg:mb-1">
          {" "}
          {headings.text3}{" "}
        </h2>
        <h2 className="md:font-semibold font-Poppins text-darkGreen text-center  sm:text-lg lg:text-2xl">
          {" "}
          {headings.text4}{" "}
        </h2>
      </div>

      <div className=" sm:hidden visible px-7 py-3">
        <p>
          <p className="text-center font-Poppins  text-lg font-extrabold mb-2 text-teal-950">Connecting Individuals With STEM Interest For Growth</p>{" "}
          <p className="font-Poppins text-center text-sm text-darkGreen " >
            We Develop Leadership Potentials Through STEM Education And Career
            Paths
          </p>
        </p>
      </div>
    </>
  );
}
